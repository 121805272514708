// ForgotPasswordPage.tsx
import React, { useState } from 'react';
import { Container, Typography, TextField, Button, Box, Link, CircularProgress } from '@mui/material';
import { useNavigate } from 'react-router-dom';

import { dataProvider } from 'api';
import { forgot_password_email_send_path } from 'service/paths';


export const forgotPasswordEmailSend = async (email: string): Promise<any> => {
  try {
    const path = forgot_password_email_send_path(encodeURIComponent(email));
    return dataProvider.get(path).json();
  } catch (error) {
    throw error;
  }
};

const ForgotPasswordForm = () => {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();


  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    setError('');
    setMessage('');

    setLoading(true); // Set loading to true when starting submission

    try {
      await forgotPasswordEmailSend(email);
      setMessage('Password reset instructions sent.');
    } catch (err: any) {
      const errorText = err?.msg || 'An error occurred, please try again or contact support if you still get this';
      setError(errorText);
    } finally {
      setLoading(false); // Reset loading to false once submission is complete
    }
  };

  return (
    <Container maxWidth="sm">
      <Box mt={8} textAlign="center">
        <Typography variant="h4" component="h1" gutterBottom>
          Forgot Password
        </Typography>
        <form onSubmit={handleSubmit}>
          <TextField
            label="Email Address"
            type="email"
            fullWidth
            margin="normal"
            value={email}
            onChange={(e) => {
              setEmail(e.target.value);
            }}
            required
          />
          {message && (
            <Typography color="success.main" variant="body2">
              {message}
            </Typography>
          )}
          {error && (
            <Typography color="error" variant="body2">
              {error}
            </Typography>
          )}
          <Button
            type="submit"
            variant="contained"
            color="primary"
            fullWidth
            disabled={loading} // Disable button when loading
            sx={{ mt: 2 }}
          >
            {loading ? <CircularProgress size={24} color="inherit" /> : 'Send Reset Link'}
          </Button>
        </form>
        <Box mt={2}>
          <Link
            component="button"
            variant="body2"
            onClick={() => navigate('/login')}
          >
            Return to Login
          </Link>
        </Box>
      </Box>
    </Container>
  );
};

export default ForgotPasswordForm;
