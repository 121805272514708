import React from 'react';

import ForgotPasswordForm from 'components/Auth/ForgotPasswordForm';
import OrderCompletionDialog from 'components/Order/OrderCompletionDialog';
import { OrderCreationStepOne } from 'components/Order/OrderCreationStepOne';
import {
  OrderCreationStepThree,
} from 'components/Order/OrderCreationStepThree';
import { OrderCreationStepTwo } from 'components/Order/OrderCreationStepTwo';
import RatingandTipping from 'components/Order/RatingandTipping';
import RetrieveOrderDialog from 'components/Order/RetrieveOrderDialog';
import { VerifyPackageDialog } from 'components/Order/VerifyPackageDialog';
import { VerifyPackageInfo } from 'components/Order/VerifyPackageInfo';
import Messages from 'pages/Messages';
import VerifyConnect from 'pages/VerifyConnect';

import Account from './pages/Account';
import Logout from './pages/Logout';
import NewAuthPage from './pages/NewAuthPage';
import OrderDetails from './pages/OrderDetails';
import Orders from './pages/Orders';
import ResetPassword from 'components/Auth/ResetPasswordForm';

const routes = [
  {
    path: "/",
    element: <Orders />,
    showInMenu: true,
    showInSettingsMenu: false,
    name: "Orders",
  },
  {
    path: "/messages",
    element: <Messages />,
    showInMenu: true,
    showInSettingsMenu: false,
    name: "Messages",
  },
  {
    path: "/messages/:channelUrl",
    element: <Messages />,
    showInMenu: false,
    showInSettingsMenu: false,
    name: "Order Details",
  },
  {
    path: "/reset-password",
    element: <ResetPassword />,
    showInMenu: false,
    showInSettingsMenu: false,
    name: "Reset Password",
  },
  {
    path: "/forgot-password",
    element: <ForgotPasswordForm />,
    showInMenu: false,
    showInSettingsMenu: false,
    name: "Forgot Password",
  },
  {
    path: "/login",
    element: <NewAuthPage />,
    showInMenu: false,
    showInSettingsMenu: false,
    name: "Auth",
  },
  {
    path: "/orders/:orderId",
    element: <OrderDetails />,
    showInMenu: false,
    showInSettingsMenu: false,
    name: "Order Details",
  },
  {
    path: "/account",
    element: <Account />,
    showInMenu: false,
    showInSettingsMenu: true,
    name: "Account",
  },
  {
    path: "/account/callback",
    element: <VerifyConnect />,
    showInMenu: false,
    showInSettingsMenu: false,
    name: "Account Callback",
  },
  {
    path: "/logout",
    element: <Logout />,
    showInMenu: false,
    showInSettingsMenu: true,
    name: "Logout",
  },
  {
    path: "/retrieve-order/:orderId",
    element: <RetrieveOrderDialog />,
    showInMenu: false,
    showInSettingsMenu: false,
    name: "Retrieve Order",
  },
  {
    path: "/order-completion/:orderId",
    element: <OrderCompletionDialog />,
    showInMenu: false,
    showInSettingsMenu: false,
    name: "Order Completion",
  },
  {
    path: "/verify-package/:orderId",
    element: <VerifyPackageDialog />,
    showInMenu: false,
    showInSettingsMenu: false,
    name: "Veify Package",
  },
  {
    path: "/verify-package-info/:orderId/:encodedImage",
    element: <VerifyPackageInfo />,
    showInMenu: false,
    showInSettingsMenu: false,
    name: "Veify Package Info",
  },
  {
    path: "/OrderCreationStepOne",
    element: <OrderCreationStepOne />,
    showInMenu: false,
    showInSettingsMenu: false,
    name: "Order Creation Step One",
  },
  {
    path: "/OrderCreationStepTwo/:addressId",
    element: <OrderCreationStepTwo />,
    showInMenu: false,
    showInSettingsMenu: false,
    name: "Order Creation Step Two",
  },
  {
    path: "/OrderCreationStepThree",
    element: <OrderCreationStepThree />,
    showInMenu: false,
    showInSettingsMenu: false,
    name: "Order Creation Step Two",
  },
  {
    path: "/order-completion/rate/:orderId",
    element: <RatingandTipping />,
    showInMenu: false,
    showInSettingsMenu: false,
    name: "Rating and Tipping",
  },
];

export default routes;
