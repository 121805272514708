import * as React from "react";

import { useSnackbar } from "notistack";
import QrReader from "react-qr-reader";

import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

interface Prop {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}
const ScannerDialog: React.FC<Prop> = ({ open, setOpen }) => {
  const { enqueueSnackbar } = useSnackbar();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [camera, setCamera] = React.useState<"environment" | "user">(
    "environment"
  ); // "user" for front camera, "environment" for back camera

  const handleClose = () => {
    setOpen(false);
  };

  const handleError = (err: any) => {
    enqueueSnackbar(
      "Failed to access camera or scan QR code. Please make sure your device supports camera access and try again.",
      {
        variant: "error",
      }
    );
    handleClose();
  };

  const handleScan = (data: string | null) => {
    const orderId = data;
    if (orderId) {
      window.location.href = `${process.env.PUBLIC_URL}/retrieve-order/${orderId}`;
    }
    // handleClose();
  };

  const switchCamera = () => {
    setCamera((prevCamera) => (prevCamera === "user" ? "environment" : "user"));
  };

  return (
    <React.Fragment>
      <Dialog
        fullScreen={fullScreen}
        fullWidth={true}
        maxWidth={"md"}
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title" sx={{ textAlign: "center" }}>
          {"Scan QR Code"}
        </DialogTitle>
        <DialogContent>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: "100%",
            }}
          >
            <QrReader
              delay={500}
              onError={handleError}
              onScan={handleScan}
              style={{ width: fullScreen ? "100%" : "80%" }}
              facingMode={camera}
            />
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={switchCamera} variant="contained">
            Switch Camera
          </Button>
          <Button onClick={handleClose} autoFocus>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};
export default ScannerDialog;
