import React, {
  useEffect,
  useState,
} from 'react';

import {
  IUserPatch,
  IWorkingHours,
} from 'interfaces';
import { useMutation } from 'react-query';
import { putUserMe } from 'service';
import { getCurrentUser } from 'utils';

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  InputLabel,
  MenuItem,
  Select,
} from '@mui/material';
import Button from '@mui/material/Button';

const daysOfWeek = [
  "monday",
  "tuesday",
  "wednesday",
  "thursday",
  "friday",
  "saturday",
  "sunday",
];

type Option = {
  label: string;
  value: number | null;
};

const generateHourOptions = (): Option[] => {
  const options: Option[] = [{ label: "Not Available", value: null }];
  for (let hour = 0; hour < 24; hour++) {
    const value = hour === 0 ? 24 : hour;
    const formattedHour = hour === 0 ? 12 : hour > 12 ? hour - 12 : hour;
    const period = hour < 12 ? "AM" : "PM";
    const formattedString = `${formattedHour}:00 ${period}`;
    options.push({ label: formattedString, value });
  }
  return options;
};

const hourOptions = generateHourOptions();

function WorkingHours({
  operationHours,
  setOperationHours,
  isWorkingHoursEditing,
  setIsWorkingHoursEditing,
}: {
  operationHours: IWorkingHours;
  setOperationHours: any;
  isWorkingHoursEditing: boolean;
  setIsWorkingHoursEditing: any;
}) {
  const [uiWorkingHours, setUiWorkingHours] = useState<IWorkingHours>([]);

  useEffect(() => {
    const defaultWorkingHours = daysOfWeek.map((day) => ({
      day,
      from: null,
      to: null,
    }));
    const newUiWorkingHours = defaultWorkingHours.map((defaultHour) => {
      const initialHour = operationHours.find(
        (hour) => hour.day === defaultHour.day
      );
      return initialHour || defaultHour;
    });
    setUiWorkingHours(newUiWorkingHours);
  }, [operationHours]);

  const handleHourChange = (
    event: React.ChangeEvent<{ value: unknown }>,
    field: "from" | "to",
    day: string
  ) => {
    const newValue = event.target.value as number | null;
    const updatedWorkingHours = uiWorkingHours.map((hour) => {
      if (hour.day === day) {
        return {
          ...hour,
          [field]: newValue === null ? null : newValue,
        };
      }
      return hour;
    });
    setUiWorkingHours(updatedWorkingHours);
  };

  /*const renderSelect = (day, field) => {
    const workingHour = uiWorkingHours.find((oh) => oh.day === day);
    const value =
      workingHour && workingHour[field] !== "Not Available"
        ? workingHour[field]
        : null;

    return (
      <Select
        value={value}
        onChange={(e) => handleHourChange(e, field, day, e.target.value)}
        fullWidth
      >
        {hourOptions.map((option, index) => (
          <MenuItem key={index} value={option.value}>
            {option.value === null ? "Not Available" : option.label}
          </MenuItem>
        ))}
      </Select>
    );
  };*/

  const handleClose = () => {
    setIsWorkingHoursEditing(false);
  };

  const putUserMeMutation = useMutation<Response, Error, IUserPatch>(
    putUserMe,
    {
      onSuccess: (_data, variables) => {
        setOperationHours(uiWorkingHours);
      },
      onError: (error) => {},
    }
  );

  const handleSave = () => {
    setIsWorkingHoursEditing(false);
    const currentUser = getCurrentUser();
    const workingHoursToSend = uiWorkingHours.filter(
      (hour) => hour.from !== null || hour.to !== null
    );

    const validWorkingHours = workingHoursToSend
      .map((hour) => {
        return {
          ...hour,
          day: hour.from === null && hour.to === null ? undefined : hour.day,
        };
      })
      .filter((hour) => hour.day !== undefined);

    putUserMeMutation.mutateAsync({
      operationHours: validWorkingHours,
      addressId: currentUser.default_address_id,
    });
  };

  return (
    <Dialog open={isWorkingHoursEditing} onClose={handleClose}>
      <DialogTitle align="center">Reset working hours</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          {daysOfWeek.map((day) => (
            <Grid item xs={6} key={day}>
              <InputLabel>{day.toUpperCase()}</InputLabel>
              <Grid container spacing={1}>
                <Grid item xs={6}>
                  <Select
                    value={
                      uiWorkingHours.find((oh) => oh.day === day)?.from || null
                    }
                    onChange={(e) => handleHourChange(e, "from", day)}
                    fullWidth
                  >
                    {hourOptions.map((option, index) => (
                      <MenuItem key={index} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </Select>
                </Grid>
                <Grid item xs={6}>
                  <Select
                    value={
                      uiWorkingHours.find((oh) => oh.day === day)?.to || null
                    }
                    onChange={(e) => handleHourChange(e, "to", day)}
                    fullWidth
                  >
                    {hourOptions.map((option, index) => (
                      <MenuItem key={index} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </Select>
                </Grid>
              </Grid>
            </Grid>
          ))}
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Cancel
        </Button>
        <Button
          onClick={handleSave}
          disabled={
            !uiWorkingHours.some(
              (hour) => hour.from !== null || hour.to !== null
            )
          }
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default WorkingHours;
