import React, {
  useEffect,
  useMemo,
  useState,
} from 'react';

import { StaticMap } from 'components/Map';
import OrderCard from 'components/OrderCard';
import ScannerDialog from 'components/ScannerDialog';
import {
  IOrder,
  OrderStatus,
} from 'interfaces';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { getOrders } from 'service';
import {
  getCurrentUser,
  isCustomer,
  isLoggedIn,
} from 'utils';

import {
  Box,
  Grid,
  Paper,
  TextField,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

const CardList: React.FC<{
  cards: IOrder[];
  header: string;
  cardBackgroundColor: string;
  cardImage: any;
}> = ({ cards, header, cardBackgroundColor, cardImage }) => {
  const [isExpanded, setIsExpanded] = React.useState(false);
  const [isClicked, setIsClicked] = useState(false);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {
    if (!isLoggedIn()) {
      window.location.href = `${process.env.PUBLIC_URL}/login`;
    }
  }, []);

  const toggleExpand = () => {
    if (isClicked) {
      setIsClicked(false);
      setIsExpanded(!isExpanded);
    } else {
      setIsClicked(true);
      setIsExpanded(true);
    }
  };

  return (
    <div
      style={{
        marginBottom: "25px",
        marginTop: "40px",
      }}
    >
      <Typography
        variant="h5"
        align="center"
        sx={{
          fontFamily: "Rubik, sans-serif",
          fontSize: isSmallScreen ? "17px" : "22px",
        }}
      >
        <span onClick={toggleExpand} style={{ cursor: "pointer" }}>
          <span
            style={{
              color: isClicked ? "#ff741e" : "#474747",
              fontSize: isSmallScreen ? "17px" : "20px",
            }}
          >
            {`${header} (${cards.length})`}{" "}
          </span>{" "}
          {isExpanded ? (
            <span style={{ fontSize: "16px" }}>▼</span>
          ) : (
            <span style={{ fontSize: "16px" }}>▶</span>
          )}
        </span>
      </Typography>
      {isExpanded &&
        cards.map((card) => {
          console.log("Card Image:", card.processedImg?.thumbnail);
          return (
            <OrderCard
              key={card.id}
              order={card}
              backgroundColor={cardBackgroundColor}
              cardImage={card.processedImg?.thumbnail || cardImage}
              isSmallScreen={isSmallScreen}
            />
          );
        })}
    </div>
  );
};

/*function OrderSection(props) {
  // Destructure the props object
  const { title, icon, orders } = props;

  // Return a JSX element
  return (
    <Paper sx={{ p: 2, mb: 2 }}>
      <Typography variant="h6" sx={{ mb: 1 }}>
        {icon} {title} ({orders.length})
      </Typography>
      {orders.map((order) => (
        <Box
          key={order._id}
          sx={{ display: "flex", alignItems: "center", mb: 1 }}
        >
          <Typography variant="body2" sx={{ mr: 1 }}>
            {order.fromCompany}, {order.createdAt}
          </Typography>
          <Typography variant="caption" color="text.secondary">
            Ordered on: {order.createdAt}
          </Typography>
        </Box>
      ))}
    </Paper>
  );
}*/

interface CreateOrderButtonProps {
  latitude?: number;
  longitude?: number;
}
const CreateOrderButton: React.FC<CreateOrderButtonProps> = ({
  latitude,
  longitude,
}) => {
  const navigate = useNavigate();

  const handleOrderCreationOpen = () => {
    const queryParams = new URLSearchParams({
      latitude: latitude?.toString() || "",
      longitude: longitude?.toString() || "",
    }).toString();
    navigate(`/OrderCreationStepOne?${queryParams}`);
  };

  return (
    <>
      <Button
        variant="contained"
        onClick={() => handleOrderCreationOpen()}
        sx={{
          color: "#ffffff",
          fontWeight: "550",
        }}
      >
        Ship package to holder
      </Button>
    </>
  );
};
const Orders: React.FC = () => {
  const [skip, setSkip] = useState(0);
  // const [conversationId, setConversationId] = useState<string | null>(null);
  const limit = 9999;
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const isMediumScreen = useMediaQuery(theme.breakpoints.down("md"));

  const orders_query = useQuery(["order", skip], () => getOrders(skip, limit), {
    keepPreviousData: true,
  });

  const orders = useMemo(() => {
    const existingOrders = new Set(orders_query.data?.orders || []);
    return [...existingOrders];
  }, [orders_query.data?.orders]);

  const readyForPickup = orders.filter(
    (order) => order.status === OrderStatus.READY_FOR_PICKUP
  );

  const awaitingDelivery = orders.filter(
    (order) =>
      order.status === OrderStatus.AWAITING_DELIVERY ||
      order.status === OrderStatus.PAYMENTMETHOD_REQUIRED ||
      order.status === OrderStatus.INDELIVERY
  );

  console.log("Orders Awaiting Delivery:", awaitingDelivery);

  useEffect(() => {
    console.log("Current User Data:", getCurrentUser());
  }, []);

  // useEffect(() => {
  //   const fetchChatUserID = async () => {
  //     try {
  //       for (const order of orders) {
  //         const orderData = await getChatUserID(order._id);
  //         const conversationId = orderData.conversationId;
  //         console.log(
  //           `Conversation ID for order ${order._id}:`,
  //           conversationId
  //         );
  //         setConversationId(conversationId);
  //       }
  //     } catch (error) {
  //       console.error("Error fetching chat user ID:", error);
  //     }
  //   };
  //   fetchChatUserID();
  // }, [orders]);

  const [location, setLocation] = useState<{
    latitude: number;
    longitude: number;
  } | null>(null);
  const [address, setAddress] = useState<string | null>(null);
  const [manualLocationInput, setManualLocationInput] = useState("");
  const [locationPermissionDenied, setLocationPermissionDenied] =
    useState(false);
  const [retry, setRetry] = useState(false);

  const HT_MAP_DEFAULT_LATITUDE = 51.0470751;
  const HT_MAP_DEFAULT_LONGITUDE = -114.1385222;

  const handleManualLocationChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setManualLocationInput(event.target.value);
  };

  const fetchAddress = (latitude: number, longitude: number) => {
    const apiKey = process.env.REACT_APP_GOOGLE_API_KEY;
    const apiUrl = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${apiKey}`;

    fetch(apiUrl)
      .then((response) => response.json())
      .then((data) => {
        if (data.results && data.results.length > 0) {
          setAddress(data.results[0].formatted_address);
        } else {
          setAddress("Address not found");
        }
      })
      .catch((error) => {
        console.error("Error fetching address:", error);
        setAddress("Error fetching address");
      });
  };

  const requestLocationPermission = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          setLocation({ latitude, longitude });
          fetchAddress(latitude, longitude);
          setLocationPermissionDenied(false);
        },
        (error) => {
          if (
            error.code === error.PERMISSION_DENIED ||
            error.code === error.TIMEOUT
          ) {
            setLocation({
              latitude: HT_MAP_DEFAULT_LATITUDE,
              longitude: HT_MAP_DEFAULT_LONGITUDE,
            });
            fetchAddress(HT_MAP_DEFAULT_LATITUDE, HT_MAP_DEFAULT_LONGITUDE);
            setLocationPermissionDenied(true);
          } else {
            console.error("Error getting location:", error.message);
          }
        }
      );
    } else {
      console.error("Geolocation is not supported by your browser.");
    }
  };

  useEffect(() => {
    requestLocationPermission();
  }, [retry]);

  useEffect(() => {
    if (manualLocationInput.trim() !== "") {
      const apiKey = process.env.REACT_APP_GOOGLE_API_KEY;
      const geocodeUrl = `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(
        manualLocationInput
      )}&key=${apiKey}`;

      fetch(geocodeUrl)
        .then((response) => response.json())
        .then((data) => {
          if (data.results && data.results.length > 0) {
            const { lat, lng } = data.results[0].geometry.location;
            setLocation({ latitude: lat, longitude: lng });
            setAddress(data.results[0].formatted_address);
          } else {
            setLocation(null);
            setAddress("Address not found");
          }
        })
        .catch((error) => {
          console.error("Error geocoding:", error);
          setLocation(null);
          setAddress("Error fetching address");
        });
    }
  }, [manualLocationInput]);

  // ToDo: count deliveries per week
  /* const previousDeliveries = orders.filter(
    (order) =>
      (order.status === OrderStatus.DELIVERED ||
        order.status === OrderStatus.CANCELLED) &&
      isWithinCurrentWeek(order.ordered_at)
  );

  const countOfPreviousDeliveries = previousDeliveries.length;

  function isWithinCurrentWeek(dateString) {
    const orderDate = new Date(dateString);
    const today = new Date();
    const dayOfWeek = today.getDay();
    const firstDayOfWeek = new Date(today);
    firstDayOfWeek.setDate(today.getDate() - dayOfWeek);

    return orderDate >= firstDayOfWeek && orderDate <= today;
  }
 */
  const previousDeliveries = orders.filter(
    (order) =>
      order.status === OrderStatus.DELIVERED ||
      order.status === OrderStatus.CANCELLED ||
      order.status === OrderStatus.PICKEDUP || 
      order.status === OrderStatus.PAID_OUT
      
  );

  const countOfPreviousDeliveries = previousDeliveries.length;

  const CO2Reduction = Math.ceil(countOfPreviousDeliveries * 1.206);

  const [confirmationCode, setConfirmationCode] = React.useState("");

  const navigate = useNavigate();
  const handleRetrieveClick = async () => {
    if (orders === undefined || orders === null) {
      return;
    }
    const order = orders.find(
      (order) => order.pickupShortCode === confirmationCode
    );
    const orderId = order?._id;

    if (order !== undefined) {
      navigate(
        `/retrieve-order/${orderId}?confirmationCode=${confirmationCode}`
      );
    } else {
      alert("Order not found. Please check the confirmation code.");
    }
  };

  const [qrReaderOpen, setQrReaderOpen] = useState(false);
  const handleQrReaderOpen = async () => {
    setQrReaderOpen(true);
  };

  //const APP_ID = "222D2AFC-D7A0-4F26-9333-F677291078F4";

  return (
    <div
      style={{
        minWidth: 275,
        maxWidth: isSmallScreen ? 400 : isMediumScreen ? 650 : 800,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        margin: "0 auto",
        overflow: "auto",
      }}
    >
      {isCustomer() && (
        <>
          <div style={{ width: "100%", marginTop: "40px" }}>
            <Typography
              variant="h5"
              gutterBottom
              sx={{
                fontFamily: "Rubik, sans-serif",
                fontSize: isSmallScreen ? "17px" : "20px",
                marginLeft: isSmallScreen ? "40px" : "0px",
                fontWeight: "550",
                color: "#474747",
              }}
            >
              Good afternoon
              {getCurrentUser()?.first_name
                ? `, ${getCurrentUser()?.first_name}`
                : ""}
            </Typography>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: isSmallScreen ? "column" : "row",
              alignItems: "center",
              justifyContent: "center",
              width: "100%",
              marginTop: "20px",
            }}
          >
            <div
              style={{
                flex: 1,
                marginBottom: isSmallScreen ? "20px" : "0",
                marginRight: "10px",
              }}
            >
              <Box
                sx={{
                  border: "1px solid grey",
                  borderRadius: "5px",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  marginRight: "10px",
                  padding: "20px",
                  height: isSmallScreen ? "auto" : "399px",
                  width: isSmallScreen ? "100%" : "100%",
                }}
              >
                {locationPermissionDenied && (
                  <div
                    style={{
                      padding: "10px",
                      backgroundColor: "red",
                      color: "white",
                      marginBottom: "10px",
                    }}
                  >
                    <Typography
                      variant="body1"
                      sx={{
                        fontFamily: "Rubik, sans-serif",
                        fontSize: isSmallScreen ? "14px" : "16px",
                      }}
                    >
                      Location services are disabled.Please enable location
                      services.
                    </Typography>

                    <button
                      onClick={() => setRetry(!retry)}
                      style={{
                        fontFamily: "Rubik, sans-serif",
                        fontSize: isSmallScreen ? "14px" : "16px",
                      }}
                    >
                      Retry
                    </button>
                  </div>
                )}
                {!locationPermissionDenied && location ? (
                  <>
                    <div>
                      <Typography
                        variant="body1"
                        sx={{
                          fontFamily: "Rubik, sans-serif",
                          fontSize: isSmallScreen ? "14px" : "16px",
                        }}
                      >
                        Your current discovery address is set to:{" "}
                      </Typography>
                      <br />
                      <br />
                    </div>
                    <div>
                      <Typography
                        variant="body1"
                        sx={{
                          fontFamily: "Rubik, sans-serif",
                          fontSize: isSmallScreen ? "14px" : "18px",
                        }}
                      >
                        <b> {address}</b>
                      </Typography>
                    </div>
                    <div>
                      <Typography
                        variant="body1"
                        sx={{
                          fontFamily: "Rubik, sans-serif",
                          fontSize: isSmallScreen ? "14px" : "18px",
                        }}
                      >
                        <b> or</b>
                      </Typography>
                    </div>
                  </>
                ) : (
                  <Typography
                    variant="body1"
                    sx={{
                      fontFamily: "Rubik, sans-serif",
                      fontSize: isSmallScreen ? "14px" : "16px",
                    }}
                  >
                    Loading location...
                  </Typography>
                )}
                <div
                  style={{
                    textAlign: "center",
                    marginTop: "20px",
                    fontFamily: "Rubik, sans-serif",
                    fontSize: isSmallScreen ? "14px" : "16px",
                  }}
                >
                  <input
                    type="text"
                    value={manualLocationInput}
                    onChange={handleManualLocationChange}
                    placeholder="Enter your location "
                    style={{
                      fontSize: "18px",
                      padding: "10px",
                      height: "auto",
                      width: "95%",
                    }}
                  />
                </div>
                <br />
                <br />

                <CreateOrderButton
                  latitude={location?.latitude}
                  longitude={location?.longitude}
                />
              </Box>
            </div>

            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flex: 1,
                margin: "5px",
                width: "100%",
              }}
            >
              {isSmallScreen ? (
                location ? (
                  <StaticMap
                    latitude={location.latitude}
                    longitude={location.longitude}
                    size={"340x340"}
                  />
                ) : (
                  <p>Loading location...</p>
                )
              ) : isMediumScreen ? (
                location ? (
                  <StaticMap
                    latitude={location.latitude}
                    longitude={location.longitude}
                    size={"300x399"}
                  />
                ) : (
                  <p>Loading location...</p>
                )
              ) : location ? (
                <StaticMap
                  latitude={location.latitude}
                  longitude={location.longitude}
                  size={"399x399"}
                />
              ) : (
                <p>Loading location...</p>
              )}
            </div>
          </div>
        </>
      )}

      {!isCustomer() && (
        <>
          <div
            style={{ width: isSmallScreen ? "85%" : "100%", marginTop: "40px" }}
          >
            <Typography
              variant="h5"
              gutterBottom
              sx={{
                fontFamily: "Rubik, sans-serif",
                fontSize: isSmallScreen ? "17px" : "20px",
                marginLeft: isSmallScreen ? "5px" : "0px",
                marginBottom: isSmallScreen ? "0px" : 4,
                fontWeight: "550",
                color: "#474747",
              }}
            >
              Good afternoon
              {getCurrentUser()?.first_name
                ? `, ${getCurrentUser()?.first_name}`
                : ""}
            </Typography>
          </div>
          {!isSmallScreen && (
            <Typography
              variant="h5"
              sx={{
                fontFamily: "Rubik, sans-serif",
                fontSize: "18px",
                marginLeft: "42%",
                fontWeight: "550",
                color: "#474747",
              }}
            >
              Your Week
            </Typography>
          )}
          <div
            style={{
              display: "flex",
              flexDirection: isSmallScreen ? "column" : "row",
              alignItems: "center",
              justifyContent: "center",
              marginTop: "20px",
              width: isSmallScreen ? "85%" : isMediumScreen ? "98%" : "100%",
            }}
          >
            <div
              style={{
                flex: 1,
                marginBottom: isSmallScreen ? "20px" : "35px",
              }}
            >
              <Grid container spacing={1}>
                <Grid item xs={12} sm={6} md={6}>
                  <Box
                    sx={{
                      border: "1px solid  #ccc",
                      borderRadius: "10px",
                      height: isSmallScreen ? 320 : 360,
                      width: isSmallScreen
                        ? "100%"
                        : isMediumScreen
                          ? "100%"
                          : "470px",
                      p: 2,
                      marginBottom: isSmallScreen ? "25px" : "0px",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    <Typography
                      variant="h5"
                      gutterBottom
                      sx={{
                        fontFamily: "Rubik, sans-serif",
                        fontSize: isSmallScreen ? "12px" : "16px",
                        mb: 2,
                        marginTop: " 9px",
                      }}
                    >
                      Customer Arrived?
                    </Typography>
                    <ScannerDialog
                      open={qrReaderOpen}
                      setOpen={setQrReaderOpen}
                    />
                    <Button
                      variant="contained"
                      color="primary"
                      sx={{
                        width: isSmallScreen
                          ? "60%"
                          : isMediumScreen
                            ? "65%"
                            : "42%",
                        color: "#ffffff",
                        fontWeight: "550",
                        padding: 1,
                        fontSize: isSmallScreen ? "13px" : "15.5px",
                      }}
                      onClick={handleQrReaderOpen}
                    >
                      Scan QR Code
                    </Button>
                    <Typography
                      sx={{
                        margin: "16px 0 10px 0",
                        fontSize: isSmallScreen ? "15px" : "18px",
                        fontWeight: "bold",
                      }}
                    >
                      or
                    </Typography>
                    <Typography
                      sx={{
                        mb: 3.2,
                        fontSize: isSmallScreen ? "15px" : "18px",
                      }}
                    >
                      Enter the Confirmation Code here:
                    </Typography>
                    <TextField
                      size={isSmallScreen ? "small" : ""}
                      fullWidth
                      id="name"
                      type="number"
                      InputProps={{
                        inputProps: {
                          max: 99999,
                          min: 10000,
                        },
                      }}
                      sx={{
                        boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                        bgcolor: "#F1F1F1",
                        mb: 3.2,
                        width: isSmallScreen
                          ? "60%"
                          : isMediumScreen
                            ? "65%"
                            : "42%",
                      }}
                      value={confirmationCode}
                      onChange={(event) =>
                        setConfirmationCode(event.target.value)
                      }
                    />
                    <Button
                      variant="contained"
                      color="primary"
                      fullWidth
                      sx={{
                        width: isSmallScreen
                          ? "60%"
                          : isMediumScreen
                            ? "65%"
                            : "42%",
                        color: "#ffffff",
                        fontWeight: "550",
                        padding: 1,
                        fontSize: isSmallScreen ? "13px" : "15.5px",
                      }}
                      onClick={handleRetrieveClick}
                    >
                      RETRIEVE ORDER
                    </Button>
                  </Box>
                </Grid>

                {isSmallScreen && (
                  <Typography
                    variant="h5"
                    sx={{
                      fontFamily: "Rubik, sans-serif",
                      fontSize: "16px",
                      marginLeft: "11px",
                      marginBottom: "10px",
                      fontWeight: "550",
                      color: "#474747",
                    }}
                  >
                    Your Week
                  </Typography>
                )}
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={6}
                  sx={{
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "center",
                  }}
                >
                  <Grid
                    container
                    sx={{
                      marginLeft: isSmallScreen
                        ? "20px"
                        : isMediumScreen
                          ? "50px"
                          : "120px",
                    }}
                  >
                    <Grid item xs={6}>
                      <Paper
                        sx={{
                          height: 124.5,
                          width: 124.5,
                          marginBottom: 2,
                          bgcolor: "#FAF1E4",
                          padding: 1.5,
                          flexDirection: "column",
                          alignItems: "center",
                          justifyContent: "center",
                          position: "relative",
                          borderRadius: "10px",
                        }}
                      >
                        <Typography
                          variant="h5"
                          sx={{
                            fontFamily: "Rubik, sans-serif",
                            fontSize: "13px",
                            marginBottom: "auto",
                            color: "grey",
                          }}
                        >
                          Completed Orders this week
                        </Typography>
                        <Typography
                          sx={{
                            fontFamily: "Rubik, sans-serif",
                            fontSize: "24px",
                            fontWeight: "550",
                            color: "#474747",
                            position: "absolute",
                            bottom: 0,
                            right: 4,
                            padding: "4px 8px",
                          }}
                        >
                          {countOfPreviousDeliveries}
                        </Typography>
                      </Paper>
                    </Grid>
                    <Grid item xs={6}>
                      <Paper
                        sx={{
                          height: 124.5,
                          width: 124.5,
                          marginBottom: 2,
                          marginLeft: 1,
                          bgcolor: "#FFEBDF",
                          borderRadius: "10px",
                        }}
                      ></Paper>
                    </Grid>
                    <Grid item xs={6}>
                      <Paper
                        sx={{
                          height: 124.5,
                          width: 124.5,
                          bgcolor: "#FFEBDF",
                          borderRadius: "10px",
                        }}
                      ></Paper>
                    </Grid>
                    <Grid item xs={6}>
                      <Paper
                        sx={{
                          height: 124.5,
                          width: 124.5,
                          marginLeft: 1,
                          padding: 1.5,
                          bgcolor: "#FAF1E4",
                          flexDirection: "column",
                          alignItems: "center",
                          justifyContent: "center",
                          position: "relative",
                          borderRadius: "10px",
                        }}
                      >
                        <Typography
                          variant="h5"
                          sx={{
                            fontFamily: "Rubik, sans-serif",
                            fontSize: "13px",
                            color: "grey",
                          }}
                        >
                          CO2 reduced this week
                        </Typography>
                        <Typography
                          sx={{
                            fontFamily: "Rubik, sans-serif",
                            fontSize: "24px",
                            fontWeight: "550",
                            color: "#474747",
                            position: "absolute",
                            bottom: 0,
                            right: 4,
                            padding: "4px 8px",
                          }}
                        >
                          {CO2Reduction} kg
                        </Typography>
                      </Paper>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </div>
          </div>
        </>
      )}

      <div
        style={{
          width: isSmallScreen ? "85%" : "100%",
        }}
      >
        <CardList
          cards={readyForPickup}
          header={isCustomer() ? "Ready for Pickup" : "Arriving"}
          cardBackgroundColor={"#FAF4EC"}
          cardImage={
            isCustomer()
              ? `${process.env.PUBLIC_URL}/Ready_for_pickup.png`
              : `${process.env.PUBLIC_URL}/card_arriving_order.png`
          }
        />

        <CardList
          cards={awaitingDelivery}
          header={"Awaiting Delivery"}
          cardBackgroundColor={"#FAF4EC"}
          cardImage={`${process.env.PUBLIC_URL}/card_awaiting_delivery_order.png`}
        />
        <CardList
          cards={previousDeliveries}
          header={"Completed Orders"}
          cardBackgroundColor={"#F4F4F4"}
          cardImage={
            isCustomer()
              ? `${process.env.PUBLIC_URL}/delivery_package.png`
              : `${process.env.PUBLIC_URL}/card_completed_order.png`
          }
        />
      </div>
    </div>
  );
};

export default Orders;
