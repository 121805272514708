import styled from "styled-components";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";

function combineStyles(...styles) {
  return function CombineStyles(theme) {
    const outStyles = styles.map((arg) => {
      if (typeof arg === "function") {
        return arg(theme);
      }
      return arg;
    });

    return outStyles.reduce((acc, val) => Object.assign(acc, val));
  };
}

const commonButtonStyle = {
  background: "#ff741e",
  color: "#ffffff",
  "&:hover": {
    backgroundColor: "#FF9858",
  },
  fontWeight: 500,
  fontSize: "0.95rem",
  width: "30%",
  boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.4)",
  fontFamily: "Rubik, sans-serif",
  marginBottom: "30px",
  marginRight: "18px",
  borderRadius: "5px",
};

const orderButtonStyle = {
  background: "#ff741e !important",
  color: "#ffffff !important",
  "&:hover": {
    backgroundColor: "#FF9858 !important",
  },
  fontWeight: "500 !important",
  fontSize: "0.95rem !important",
  width: "auto !important",
  boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.4) !important",
  fontFamily: "Rubik, sans-serif !important",
  marginTop: "18px !important",
  marginRight: "18px !important",
  borderRadius: "5px !important",
};

const orderButtonDangerStyle = {
  background: "#eb445a !important",
  color: "#ffffff !important",
  "&:hover": {
    backgroundColor: "#cf3c4f !important",
  },
  fontWeight: "500 !important",
  fontSize: "0.95rem !important",
  width: "auto !important",
  boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.4) !important",
  fontFamily: "Rubik, sans-serif !important",
  marginTop: "18px !important",
  marginRight: "18px !important",
  borderRadius: "5px !important",
};

const baseButtonMediaQueries = {
  "@media (max-width: 768px)": {
    fontSize: "0.8rem !important",
    marginTop: "5px !important",
  },
  "@media (max-width: 480px)": {
    fontSize: "0.53rem !important",
    marginTop: "0px !important",
  },
};

const newButtonMediaQueries = {
  "@media (max-width: 768px)": {
    ...baseButtonMediaQueries,
    marginRight: "8px",
    width: "30%",
  },
  "@media (max-width: 480px)": {
    ...baseButtonMediaQueries,
    width: "35%",
    marginRight: "8px",
  },
};

const orderButtonMediaQueries = {
  "@media (max-width: 768px)": {
    ...baseButtonMediaQueries,
    width: "50%",
  },
  "@media (max-width: 480px)": {
    ...baseButtonMediaQueries,
    width: "30%",
  },
};

const commonTypographyStyle = {
  color: "primary !important",
  fontFamily: "Rubik, sans-serif !important",
};

const createTypographyStyle = (
  fontWeight,
  fontSize,
  marginBottom,
  marginTop,
  lineHeight,
  marginLeft,
  color,
  mediaQueries = {}
) => ({
  ...commonTypographyStyle,
  fontWeight: `${fontWeight} !important`,
  fontSize: `${fontSize} !important`,
  marginBottom: `${marginBottom} !important`,
  marginTop: `${marginTop} !important`,
  lineHeight: `${lineHeight} !important`,
  marginLeft: `${marginLeft} !important`,
  color: `${color} !important`,
  ...mediaQueries,
});

const typographyStyle = createTypographyStyle(
  "400",
  "1.02rem",
  "5px",
  "-12px",
  null,
  null,
  null,
  {
    "@media (max-width: 768px)": {
      fontSize: "0.85rem",
    },
    "@media (max-width: 480px)": {
      fontSize: "0.7rem",
    },
  }
);

const typographyStyle2 = createTypographyStyle(
  "550",
  "1.14rem",
  "5px",
  "8px",
  null,
  null,
  null,
  {
    "@media (max-width: 768px)": {
      fontSize: "1rem !important",
      marginTop: "5px",
    },
    "@media (max-width: 480px)": {
      fontSize: "0.85rem !important",
      marginTop: "0px",
    },
  }
);

const typographyStyle3 = createTypographyStyle(
  "400",
  "1rem",
  "10px",
  null,
  "1.1",
  null,
  null,
  {
    "@media (max-width: 768px)": {
      fontSize: "0.8rem !important",
    },
    "@media (max-width: 480px)": {
      fontSize: "0.67rem !important",
    },
  }
);

const typographyStyle4 = createTypographyStyle(
  "null",
  "1.2rem",
  1,
  "45px",
  null,
  "30px",
  null,
  {
    "@media (max-width: 768px)": {
      marginTop: "30px !important",
      fontSize: "0.95rem !important",
    },
    "@media (max-width: 480px)": {
      marginTop: "0px !important",
      fontSize: "0.9rem !important",
    },
  }
);

const typographyStyle5 = createTypographyStyle(
  "null",
  "0.98rem",
  0.3,
  null,
  "1.6",
  "30px",
  null,
  {
    "@media (max-width: 768px)": {
      fontSize: "1rem !important",
    },
    "@media (max-width: 480px)": {
      fontSize: "0.9rem !important",
    },
  }
);

const typographyStyle6 = createTypographyStyle(
  "null",
  "1rem",
  null,
  "20px",
  "1.2",
  null,
  null,
  {
    "@media (max-width: 768px)": {
      fontSize: "0.85rem !important",
      marginBottom: "36px !important",
    },
    "@media (max-width: 480px)": {
      fontSize: "0.74rem !important",
    },
  }
);

const typographyStyle7 = createTypographyStyle(
  "null",
  "0.95rem",
  null,
  null,
  null,
  null,
  null,
  {
    "@media (max-width: 768px)": {
      fontSize: "0.9rem !important",
    },
    "@media (max-width: 480px)": {
      fontSize: "0.8rem !important",
    },
  }
);

const typographyStyle8 = createTypographyStyle(
  null,
  "0.78rem",
  null,
  "5px",
  "1.15",
  null,
  "#8e8e8e",
  {
    "@media (max-width: 768px)": {
      fontSize: "0.7rem !important",
    },
    "@media (max-width: 480px)": {
      fontSize: "0.65rem !important",
    },
  }
);

const typographyStyle9 = createTypographyStyle(
  "null",
  "0.89rem",
  null,
  "25px",
  null,
  null,
  null,
  {
    "@media (max-width: 768px)": {
      fontSize: "0.9rem !important",
    },
    "@media (max-width: 480px)": {
      fontSize: "0.87rem !important",
    },
  }
);

const typographyStyle10 = createTypographyStyle(
  null,
  "0.9rem",
  null,
  "25px",
  "1.18",
  "10px",
  null,
  {
    "@media (max-width: 768px)": {
      fontSize: "0.76rem !important",
    },
    "@media (max-width: 480px)": {
      fontSize: "0.68rem !important",
    },
  }
);

const typographyStyle11 = createTypographyStyle(
  "550",
  "1.8rem",
  "25px",
  null,
  null,
  null,
  "text.secondary",
  {
    "@media (max-width: 768px)": {
      fontSize: "1.2rem !important",
    },
    "@media (max-width: 480px)": {
      fontSize: "1rem !important",
    },
  }
);

const typographyStyle12 = createTypographyStyle(
  null,
  "1.2rem",
  "12px",
  "10px",
  "null",
  "null",
  "text.secondary",
  {
    "@media (max-width: 768px)": {
      fontSize: "1rem !important",
    },
    "@media (max-width: 480px)": {
      fontSize: "0.9rem !important",
    },
  }
);

const typographyStyle13 = createTypographyStyle(
  "550",
  "1.06rem",
  "15px",
  "15px",
  null,
  null,
  "text.secondary",
  {
    "@media (max-width: 768px)": {
      fontSize: "0.8rem !important",
    },
    "@media (max-width: 480px)": {
      fontSize: "0.75rem !important",
    },
  }
);

const typographyStyle14 = createTypographyStyle(
  "550",
  "2.2rem",
  "15px",
  "21px",
  null,
  null,
  "#ff741e",
  {
    "@media (max-width: 768px)": {
      fontSize: "1.6rem !important",
    },
    "@media (max-width: 480px)": {
      fontSize: "1.4rem !important",
    },
  }
);

const typographyStyle15 = createTypographyStyle(
  null,
  "0.95rem",
  null,
  null,
  "1.6rem",
  null,
  "text.secondary",
  {
    "@media (max-width: 768px)": {
      fontSize: "0.9rem !important",
    },
    "@media (max-width: 480px)": {
      fontSize: "0.8rem !important",
    },
  }
);

const typographyStyle16 = createTypographyStyle(
  "400",
  "0.9rem",
  null,
  "2.5rem",
  null,
  null,
  null,
  {
    "@media (max-width: 768px)": {
      fontSize: "0.67rem !important",
      marginBottom: "0.7rem !important",
    },
    "@media (max-width: 480px)": {
      fontSize: "0.6rem !important",
      marginBottom: "0.7rem !important",
    },
  }
);

const typographyStyle17 = createTypographyStyle(
  "550",
  "1.5rem",
  "30px",
  "-20px",
  null,
  null,
  "#ff741e",
  {
    "@media (max-width: 768px)": {
      fontSize: "1.2rem !important",
    },
    "@media (max-width: 480px)": {
      fontSize: "1rem !important",
    },
  }
);

const typographyStyle18 = createTypographyStyle(
  "400",
  "1rem",
  "10px",
  "21px",
  "1.3",
  null,
  null,
  {
    "@media (max-width: 768px)": {
      fontSize: "0.8rem !important",
    },
    "@media (max-width: 480px)": {
      fontSize: "0.67rem !important",
    },
  }
);

const typographyStyle19 = createTypographyStyle(
  null,
  "0.95rem",
  null,
  null,
  null,
  null,
  "text.secondary",
  {
    "@media (max-width: 768px)": {
      fontSize: "0.9rem !important",
    },
    "@media (max-width: 480px)": {
      fontSize: "0.8rem !important",
    },
  }
);

const typographyStyleForHolder = createTypographyStyle(
  "550",
  "0.96rem",
  null,
  null,
  null,
  null,
  "#ff741e",
  {
    "@media (max-width: 768px)": {
      fontSize: "0.9rem !important",
    },
    "@media (max-width: 480px)": {
      fontSize: "0.8rem !important",
    },
  }
);

const typographyStyleForHours = createTypographyStyle(
  "550",
  "0.96rem",
  null,
  null,
  null,
  null,
  "text.secondary",
  {
    "@media (max-width: 768px)": {
      fontSize: "0.9rem !important",
    },
    "@media (max-width: 480px)": {
      fontSize: "0.8rem !important",
    },
  }
);

const typographyStyleRetrieveOrder = createTypographyStyle(
  null,
  "0.9rem",
  "0.9rem",
  "5px",
  "1.13",
  null,
  "text.secondary",
  {
    "@media (max-width: 768px)": {
      fontSize: "0.76rem !important",
    },
    "@media (max-width: 480px)": {
      fontSize: "0.68rem !important",
    },
  }
);

const baseButtonStyle = combineStyles(
  commonButtonStyle,
  baseButtonMediaQueries
);
const newButtonStyle = combineStyles(commonButtonStyle, newButtonMediaQueries);
const buttonStyleOrder = combineStyles(
  orderButtonStyle,
  orderButtonMediaQueries
);

const buttonDangerStyleOrder = combineStyles(
  orderButtonDangerStyle,
  orderButtonMediaQueries
);

export const StyledButton1 = styled(Button)(baseButtonStyle);
export const StyledButton2 = styled(Button)(newButtonStyle);
export const StyledButton3 = styled(Button)(buttonStyleOrder);
export const StyledButton4 = styled(Button)(buttonDangerStyleOrder);
export const StyledTypography = styled(Typography)(typographyStyle);
export const StyledTypography2 = styled(Typography)(typographyStyle2);
export const StyledTypography3 = styled(Typography)(typographyStyle3);
export const StyledTypography4 = styled(Typography)(typographyStyle4);
export const StyledTypography5 = styled(Typography)(typographyStyle5);
export const StyledTypography6 = styled(Typography)(typographyStyle6);
export const StyledTypography7 = styled(Typography)(typographyStyle7);
export const StyledTypography8 = styled(Typography)(typographyStyle8);
export const StyledTypography9 = styled(Typography)(typographyStyle9);
export const StyledTypography10 = styled(Typography)(typographyStyle10);
export const StyledTypography11 = styled(Typography)(typographyStyle11);
export const StyledTypography12 = styled(Typography)(typographyStyle12);
export const StyledTypography13 = styled(Typography)(typographyStyle13);
export const StyledTypography14 = styled(Typography)(typographyStyle14);
export const StyledTypography15 = styled(Typography)(typographyStyle15);
export const StyledTypography16 = styled(Typography)(typographyStyleForHolder);
export const StyledTypography17 = styled(Typography)(typographyStyleForHours);
export const StyledTypography18 = styled(Typography)(
  typographyStyleRetrieveOrder
);
export const StyledTypography19 = styled(Typography)(typographyStyle16);
export const StyledTypography20 = styled(Typography)(typographyStyle17);
export const StyledTypography21 = styled(Typography)(typographyStyle18);
export const StyledTypography22 = styled(Typography)(typographyStyle19);
