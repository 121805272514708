import React, {
  FC,
  useState,
} from 'react';

import { UserRole } from 'interfaces';
import { useNavigate } from 'react-router-dom';
import { getEmailConfirmSend } from 'service/requests/users';

import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import LoadingButton from '@mui/lab/LoadingButton';
import {
  Button,
  Divider,
  Select,
  TextField,
} from '@mui/material';
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import Link from '@mui/material/Link';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';

import FormFieldError from '../../components/FormFieldError';
import * as Controller from './controller';

interface ComponentProps {}

const SignupFormContent: FC<ComponentProps> = ({}) => {
  const { handleSignUp } = Controller.useSignUp();
  const { handleEmailConfirmation } = Controller.useConfirmation();
  const [userEmail, setUserEmail] = useState("");
  const [selectedRole, setSelectedRole] = useState(UserRole.CUSTOMER);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();
  const [isVerificationDialogOpen, setVerificationDialogOpen] = useState(false);
  const [verificationCode, setVerificationCode] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [errorState, setErrorState] = useState({
    errorMsg: "",
    phoneErrorMsg: "",
    passwordErrorMsg: "",
    confirmPasswordErrorMsg: "",
  });

  const handleSignInClick = () => {
    navigate("/login");
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const hasValidationErrors = Object.values(errorState).some(
      (errorMessage) => errorMessage !== ""
    );
    if (hasValidationErrors) {
      return;
    }
    setIsSubmitting(true);
    const data = new FormData(event.currentTarget);
    const signUpData = {
      user: {
        email: data.get("email")?.toString().trim() || "",
        phone: data.get("phone")?.toString().trim() || "",
        password: data.get("password")?.toString().trim() || "",
        name: {
          first: data.get("firstName")?.toString().trim() || "",
          last: data.get("lastName")?.toString().trim() || "",
        },
        role: selectedRole,
      },
    };

    handleSignUp(signUpData, () => {
      setVerificationDialogOpen(true);
    })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        setIsSubmitting(false);
      });

    setUserEmail(signUpData.user.email);
  };

  const showSuccessDialog = () => {
    window.alert("Verification successful. Please sign in.");
  };

  const handleVerificationConfirm = (event) => {
    console.log("handleVerificationConfirm", event);
    handleEmailConfirmation(
      {
        email: userEmail,
        email_confirmation_code: verificationCode,
      },

      () => {
        showSuccessDialog();
        window.location.reload();
      }
    )
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        setVerificationDialogOpen(false);
      });
  };

  const handleResendCode = () => {
    getEmailConfirmSend({ email: userEmail })
      .then(() => {
        console.log("Verification code resent successfully");
      })
      .catch((error) => {
        console.error(error);
      });
  };
  return (
    <>
      <div style={{ textAlign: "left" }}>
        <Typography
          component="h1"
          variant="h5"
          sx={{
            marginTop: 10,
            fontSize: "23px",
            "@media (max-width: 900px)": {
              fontSize: "19px",
              marginTop: 11,
            },
            marginBottom: 1,
            textShadow: "2px 2px 4px rgba(0, 0, 0, 0.5)",
            fontWeight: "500",
            fontFamily: "Rubik, sans-serif",
          }}
        >
          Welcome!
        </Typography>
        <Typography
          fontSize={12}
          sx={{ fontFamily: "Rubik, sans-serif", marginBottom: 2 }}
        >
          Secure, community-based, package storage with Hold This
        </Typography>
      </div>
      <Typography fontSize={15} sx={{ fontFamily: "Rubik, sans-serif" }}>
        I'm signing up as a ...
      </Typography>

      <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 1 }}>
        <Select
          value={selectedRole}
          onChange={(event) => setSelectedRole(event.target.value as UserRole)}
          label="User Role"
          sx={{
            marginBottom: "10px",
            width: "100%",
          }}
        >
          <MenuItem value={UserRole.HOLDER}>
            Holder (receive and hold packages for a fee)
          </MenuItem>
          <MenuItem value={UserRole.CUSTOMER}>
            Customer (send my packages for safekeeping)
          </MenuItem>
        </Select>
        <Divider sx={{ borderBottomWidth: 3.5 }} />
        <TextField
          data-cy="firstName"
          type="text"
          name="firstName"
          label="First name"
          variant="outlined"
          margin="normal"
          size="small"
          fullWidth
          required
        />
        <TextField
          data-cy="lastName"
          type="text"
          name="lastName"
          label="Last name"
          variant="outlined"
          margin="normal"
          size="small"
          fullWidth
          required
        />
        <TextField
          data-cy="email"
          name="email"
          label="Email"
          type="email"
          variant="outlined"
          size="small"
          margin="normal"
          fullWidth
          required
          onChange={(e) => {
            const inputValue = e.target.value;
            const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
            if (!emailRegex.test(inputValue)) {
              setErrorState({
                ...errorState,
                errorMsg: "Invalid email address",
              });
            } else {
              setErrorState({ ...errorState, errorMsg: "" });
            }
          }}
          error={Boolean(errorState.errorMsg !== "")}
          helperText={
            errorState.errorMsg ? (
              <FormFieldError text={errorState.errorMsg} />
            ) : null
          }
        />
        <TextField
          data-cy="phone"
          name="phone"
          label="Phone Number"
          type="phone"
          variant="outlined"
          size="small"
          margin="normal"
          fullWidth
          required
          onChange={(e) => {
            const inputValue = e.target.value;
            const phoneRegex = /^\d{10}$/;
            if (!phoneRegex.test(inputValue)) {
              setErrorState({
                ...errorState,
                phoneErrorMsg: "Invalid phone number",
              });
            } else {
              setErrorState({ ...errorState, phoneErrorMsg: "" });
            }
          }}
          error={Boolean(errorState.phoneErrorMsg !== "")}
          helperText={
            errorState.phoneErrorMsg ? (
              <FormFieldError text={errorState.phoneErrorMsg} />
            ) : null
          }
        />
        <TextField
          data-cy="password"
          name="password"
          label="Password"
          type={showPassword ? "text" : "password"}
          variant="outlined"
          size="small"
          margin="normal"
          fullWidth
          required
          onChange={(e) => {
            const inputValue = e.target.value;
            if (inputValue !== confirmPassword && confirmPassword.length > 0) {
              setErrorState({
                ...errorState,
                passwordErrorMsg: "Passwords do not match",
              });
            } else {
              setErrorState({
                ...errorState,
                passwordErrorMsg: "",
              });
            }
            setPassword(inputValue);
          }}
          error={Boolean(errorState.passwordErrorMsg !== "")}
          helperText={
            errorState.passwordErrorMsg ? (
              <FormFieldError text={errorState.passwordErrorMsg} />
            ) : null
          }
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  onClick={() => setShowPassword(!showPassword)}
                  edge="end"
                  aria-label="toggle password visibility"
                >
                  {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <TextField
          data-cy="confirmPassword"
          type={showPassword ? "text" : "password"}
          name="confirmPassword"
          label="Confirm Password"
          variant="outlined"
          fullWidth
          required
          margin="normal"
          size="small"
          onChange={(e) => {
            const inputValue = e.target.value;
            if (inputValue !== password && password.length > 0) {
              setErrorState({
                ...errorState,
                confirmPasswordErrorMsg: "Passwords do not match",
              });
            } else {
              setErrorState({
                ...errorState,
                confirmPasswordErrorMsg: "",
              });
            }
            setConfirmPassword(inputValue);
          }}
          error={Boolean(errorState.confirmPasswordErrorMsg !== "")}
          helperText={
            errorState.confirmPasswordErrorMsg ? (
              <FormFieldError text={errorState.confirmPasswordErrorMsg} />
            ) : null
          }
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  onClick={() => setShowPassword(!showPassword)}
                  edge="end"
                  aria-label="toggle password visibility"
                >
                  {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />

        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginBottom: "20px",
          }}
        >
          <LoadingButton
            data-cy="submit"
            fullWidth
            type="submit"
            loading={isSubmitting}
            onClick={() => setVerificationDialogOpen(true)}
            sx={{
              mt: 3,
              mb: 2,
              padding: "8px 5px",
              fontSize: "0.75rem",
              fontWeight: "600",
              width: "30%",
              backgroundColor: "#ff741e",
              borderRadius: "20px",
              boxShadow: "0px 4px 4px #00000040",
              fontFamily: "Rubik, sans-serif",
              color: "#FFFFFF",
              "&:hover": {
                backgroundColor: "#ffac1e",
              },
              textAlign: "center",
            }}
          >
            SIGNUP
          </LoadingButton>
        </div>
      </Box>
      <Dialog
        open={isVerificationDialogOpen}
        onClose={() => setVerificationDialogOpen(false)}
      >
        <DialogContent>
          <Typography variant="body1" style={{ marginBottom: "16px" }}>
            We've sent a verification code to your email. If you don’t see it
            after a couple minutes, please check your Spam folder or ‘RESEND
            CODE’.
          </Typography>
          <DialogTitle>Enter Verification Code</DialogTitle>
          <TextField
            label="Verification Code"
            variant="outlined"
            fullWidth
            value={verificationCode}
            onChange={(e) => setVerificationCode(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <DialogActions>
            <Button onClick={handleVerificationConfirm}>Confirm</Button>
            <Button onClick={handleResendCode}>Resend code</Button>
          </DialogActions>
        </DialogActions>
      </Dialog>

      <Box display="flex" justifyContent="center">
        <Link
          href="login"
          onClick={handleSignInClick}
          variant="body2"
          style={{ textDecoration: "none" }}
        >
          <span style={{ color: "#474747", fontFamily: "Rubik, sans-serif" }}>
            Already have an account?
          </span>{" "}
          <span style={{ color: "#ff741e", fontFamily: "Rubik, sans-serif" }}>
            Log In
          </span>
        </Link>
      </Box>
    </>
  );
};

export default React.memo(SignupFormContent);
