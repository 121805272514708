export const getToken = () => {
  const localTokens = JSON.parse(
    localStorage.getItem("tokens@store") || "null",
  );
  return localTokens?.access_token;
};

export const getRefreshToken = () => {
  const localTokens = JSON.parse(
    localStorage.getItem("tokens@store") || "null",
  );
  const refreshToken = localTokens?.refresh_token;
  if (isExpired(getExpirationDate(refreshToken))) {
    localStorage.removeItem("user@store");
    localStorage.removeItem("tokens@store");
    window.location.reload();
    return null;
  }
  return refreshToken;
};

export const setHeaders = (customHeaders: any) => (request: any) => {
  const token = getToken();
  const regex = /login/g;
  if (token && !regex.test(request.url)) {
    request.headers.set("Authorization", `Bearer ${token}`);
    if (customHeaders?.length) {
      customHeaders.forEach((item: any) =>
        request.headers.set(item.header, item.value),
      );
    }
  }
};

const isExpired = (exp: any) => {
  if (!exp) {
    return false;
  }

  return Date.now() > exp;
};

const getExpirationDate = (jwtToken: any) => {
  if (!jwtToken) {
    return null;
  }

  const jwt = JSON.parse(atob(jwtToken.split(".")[1]));

  // multiply by 1000 to convert seconds into milliseconds
  return (jwt && jwt.exp && jwt.exp * 1000) || null;
};
