import ky from "ky";
import { setHeaders, getRefreshToken } from "./auth";
import { ServerErrorCodesEnum } from "interfaces";


export const clearLocalStorageAndReload = () => {
  localStorage.clear();
  sessionStorage.clear();
  // reload the page manually to be redirected to login screen if storage is empty
  window.location.replace(`${window.location.origin}${process.env.PUBLIC_URL}/login`);
  // window.location.reload();
};

export default (baseUrl: string, customHeaders: string[] = []) => {
  return ky.create({
    prefixUrl: `${baseUrl}`,
  //     headers: {
  //  'Content-Type': 'application/json'
  // },
    credentials: 'include',
    timeout: 30000,
    retry: {
      limit: 2,
      methods: ["get"],
      statusCodes: [408, 413, 429, 500, 502, 503, 504],
    },
    hooks: {
      beforeRequest: [setHeaders(customHeaders)],
      afterResponse: [
        async (_request: any, _options: any, response: Response) => {
          if (!response.ok) {
            const error = await response.json();
            // sometimes in beforeRetry hook error.response doesn't contain status
            // so we check 401 status here also just to double check
            if (response?.status === 401) {
              // not to ask for refresh token if it's login auth error (wrong credentials)
              if (
                error?.code === ServerErrorCodesEnum.NOT_AUTHENTICATED ||
                error?.code === ServerErrorCodesEnum.TOKEN_NOT_VALID
              ) {
                clearLocalStorageAndReload()
              } else throw error;

              return;
            }
            if (
              response?.status === 403 &&
              error?.code === ServerErrorCodesEnum.PROFILE_DEACTIVATED
            ) {
              localStorage.clear();
              sessionStorage.clear();
              window.location.replace(
                `${window.location.origin}${process.env.PUBLIC_URL}/deactivated-account`,
              );
              return;
            }

            if(response?.status ===480){
              throw {...error, status: response?.status}
            }
            throw error;
          }
          return response;
        },
      ],
      beforeRetry: [
        async ({ request, error }: { request: any; error: any }) => {
          if (error?.response?.status === 401) {
            clearLocalStorageAndReload()
            return;
          }
        },
      ],
    },
  });
};
