import React from 'react'
import SbChannel from '@sendbird/uikit-react/GroupChannel'
import SbChannelList from '@sendbird/uikit-react/GroupChannelList'
import { useNavigate } from 'react-router-dom';

export const DesktopChannelList = ({channelUrl}: {channelUrl: string}) => {
  const navigate = useNavigate();

  return (
    <div className='sendbird-chat-desktop'>
      <div className='sendbird-chat-desktop__channel-list'>
        <SbChannelList
          selectedChannelUrl={channelUrl}
          allowProfileEdit={false}
          disableAutoSelect
          onChannelCreated={(channel) => {}}
          onChannelSelect={(channel) => {
            if (channel) {
              const orderData = JSON.parse(channel.data);
              navigate(`/messages/${channel.url}?orderId=${orderData.orderId}`);
            }
          }}
        />
      </div>
      <SbChannel channelUrl={channelUrl} />
    </div>
  )
}