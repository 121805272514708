import React, { useEffect } from "react";
import { isLoggedIn } from "utils";
import { useLocation } from "react-router-dom";
import { verfiyOnReturn } from "service";
import LoadingIndicator from "components/LoadingIndicator";


function useQuery() {
  return new URLSearchParams(useLocation().search);
}

// verfiy the connect onborad account process 
const VerifyConnect: React.FC = () => {
  let query = useQuery();
  let code = query.get("code");
  let accountId = query.get("accountId");

  const verifyRequest = (code: string) => {
    verfiyOnReturn(code, accountId).then(res => {
      window.location.replace(
        `${window.location.origin}${process.env.PUBLIC_URL}/account`
      );
    })
    .catch(error => {
      window.location.replace(
        `${window.location.origin}${process.env.PUBLIC_URL}/account`
      );
    })
  }

  useEffect(() => {
    if (!isLoggedIn()) {
      window.location.href = `${process.env.PUBLIC_URL}/login`;
      return;
    }

    verifyRequest(code!);
  }, []);

  return (<LoadingIndicator />)
}
export default VerifyConnect;