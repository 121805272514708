import React from "react";
import { useLocation } from "react-router-dom";
import NavBar from "../Navbar/index";

function ConditionalNavBar() {
  const location = useLocation();

  const pathsToHideNavBar = ['/login', '/forgot-password', '/reset-password'];

  const shouldRenderNavBar = !pathsToHideNavBar.includes(location.pathname);

  return shouldRenderNavBar ? <NavBar /> : null;
}

export default ConditionalNavBar;
