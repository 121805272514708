import React, { useEffect, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import SBProvider from "@sendbird/uikit-react/SendbirdProvider";
import { getCurrentUser, isCustomer, isHolder, isLoggedIn } from "utils";
import { IOrderGet, UserRole } from "interfaces";
import { getChatAccessToken, getOrder } from "service";
import LoadingIndicator from "components/LoadingIndicator";
import { ChatColorSet } from "components/Message";
import { DesktopChannelList } from "components/Message";
import { MobileChannelList } from "components/Message";
import "../components/Message/Messages.css";
import {
  Avatar,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
} from "@mui/material";
import { format } from "date-fns";

const Messages: React.FC = () => {
  if (!isLoggedIn()) {
    window.location.href = `${process.env.PUBLIC_URL}/login`;
  }
  const defaultImageUrl = isCustomer()
    ? `${process.env.PUBLIC_URL}/delivery_package.png`
    : `${process.env.PUBLIC_URL}/card_completed_order.png`;

  const { channelUrl } = useParams();
  // Get the search parameters
  const [searchParams] = useSearchParams();
  const orderId = searchParams.get("orderId");

  const [orderData, setOrderData] = useState<IOrderGet | null>(null);
  const [imageSrc, setImageSrc] = useState<string | undefined>(defaultImageUrl);

  const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

  const APP_ID = "222D2AFC-D7A0-4F26-9333-F677291078F4";
  const userData = getCurrentUser();
  const role = isHolder() ? UserRole.HOLDER : UserRole.CUSTOMER;
  const chatUserId = `${role}${userData.id}`;
  const [userId] = useState<string | null>(chatUserId);
  const [accessToken, setAccessToken] = useState<string | null>(null);
  const [isConnecting, setIsConnecting] = React.useState(true);

  const fetchConversationAndOrder = async () => {
    try {
      const [conversation, order] = await Promise.all([
        fetchConversationId(),
        fetchOrderById(),
      ]);
      setAccessToken(conversation);
      setOrderData(order);
      if (order && order.processedImg && order.processedImg.thumbnail) {
        setImageSrc(order.processedImg.thumbnail);
      }
      setIsConnecting(false);
    } catch (error) {
      setIsConnecting(false);
    }
  };

  const fetchConversationId = async () => {
    try {
      const result = await getChatAccessToken();
      return result.accessToken;
    } catch (error) {
      console.error("Error fetching conversation ID:", error);
      throw error;
    }
  };

  const fetchOrderById = async () => {
    if (!orderId) {
      return null;
    }

    try {
      const result: any = await getOrder(orderId!);
      return result.order;
    } catch (error) {
      console.error("Error fetching fetchOrderById:", error);
      throw error;
    }
  };

  useEffect(() => {
    fetchConversationAndOrder();
  }, [searchParams, channelUrl]);

  const handleImageError = () => {
    setImageSrc(defaultImageUrl); // Set default image based on userType on error
  };

  return (
    <>
      {isConnecting && <LoadingIndicator />}
      {!isConnecting && (
        <div className="messages-content">
          {orderId && orderData && (
            <div className="order-item">
              <ListItem alignItems="flex-start">
                <ListItemAvatar>
                  <Avatar
                    alt="User Avatar"
                    src={imageSrc}
                    onError={handleImageError}
                    sx={{ width: 70, height: 70, marginInlineEnd: '16px' }}
                  />
                </ListItemAvatar>
                <ListItemText
                  primary={
                    <div>
                      <Typography variant="body1" color="textPrimary">
                        {orderData.fromCompany}
                      </Typography>
                      <Typography variant="body2" color="textSecondary">
                        {orderData.referenceNo}
                      </Typography>
                    </div>
                  }
                  secondary={
                    <div>
                      <Typography variant="body2" color="textSecondary">
                        {orderData.courier}
                      </Typography>
                      <Typography variant="body2" color="textSecondary">
                        Created At:{" "}
                        {format(new Date(orderData.createdAt), "PPpp")}
                      </Typography>
                    </div>
                  }
                />
              </ListItem>
            </div>
          )}
          <div className="sendbird-app">
            <SBProvider
              appId={APP_ID}
              userId={userId!}
              accessToken={accessToken!}
              allowProfileEdit={false}
              theme={"light"}
              colorSet={ChatColorSet}
              breakpoint={isMobile}
            >
              {isMobile ? (
                <MobileChannelList channelUrl={channelUrl} />
              ) : (
                <DesktopChannelList channelUrl={channelUrl ? channelUrl : ""} />
              )}
            </SBProvider>
          </div>
        </div>
      )}
    </>
  );
};
export default Messages;
