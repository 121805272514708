import {
  ErrorDual,
  IOrderPatch,
  IOrderPutProcess,
} from 'interfaces';
import { useSnackbar } from 'notistack';
import { useMutation } from 'react-query';
import {
  patchOrder,
  putOrderPickUp,
  putOrderProcess,
  putOrderRate,
} from 'service';
import { parsePathErrorDual } from 'utils';

export function usePatchOrderMutation() {
  const { enqueueSnackbar } = useSnackbar();

  const patchOrderMutation = useMutation<Response, ErrorDual, IOrderPatch>(
    patchOrder,
    {
      onSuccess: (_data, variables) => {},
      onError: (error) => {
        const errorText = error?.message || parsePathErrorDual(error);
        enqueueSnackbar(errorText, {
          variant: "error",
        });
      },
    },
  );
  return patchOrderMutation;
}


export function usePutOrderProcessMutation() {
  const { enqueueSnackbar } = useSnackbar();

  const putOrderProcessMutation = useMutation<Response, ErrorDual, IOrderPutProcess>(
    putOrderProcess,
    {
      onSuccess: (_data, variables) => {},
      onError: (error) => {
        const errorText = error?.message || parsePathErrorDual(error);
        enqueueSnackbar(errorText, {
          variant: "error",
        });
        throw errorText;
      },
    },
  );
  return putOrderProcessMutation;
}


export function usePutOrderPickUpMutation() {
  const { enqueueSnackbar } = useSnackbar();

  const putOrderPickUpMutation = useMutation<any, any, any>(
    putOrderPickUp,
    {
      onSuccess: (_data, variables) => Promise.resolve(),
      onError: (error: any) => {
        const errorText = error?.msg || parsePathErrorDual(error);
        
        enqueueSnackbar(errorText, {
          variant: "error",
        });
        throw errorText;
      },
    },
  );

  const handleOrderPickUp = async (
    params: any,
    onSuccess: () => void
  ): Promise<any> => {
    try {
      const data = await putOrderPickUpMutation.mutateAsync(params);
      onSuccess(); 
      return Promise.resolve();
    } catch (error) {
      throw error;
    }
  };
  
  return {
    handleOrderPickUp,
  };
}

export  function useRateAndTipMutation() {
  const { enqueueSnackbar } = useSnackbar();
  const rateAndTipMutation = useMutation<any, any, any>(
    putOrderRate,
    {
      onSuccess: (_data, variables) => Promise.resolve(),
      onError: (error: any) => {
        const errorText = error?.msg || parsePathErrorDual(error);
        
        enqueueSnackbar(errorText, {
          variant: "error",
        });
        throw errorText;
      },
    },
  );
   return rateAndTipMutation;
}

