import './App.css';

import React from 'react';

import { SnackbarProvider } from 'notistack';
import {
  QueryClient,
  QueryClientProvider,
} from 'react-query';
import {
  BrowserRouter,
  Route,
  Routes,
} from 'react-router-dom';

import routes from '../../routes';
import ConditionalNavBar from '../Navbar/ConditionalNavBar';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 3,
      cacheTime: 300_000, // 5m
      refetchOnWindowFocus: false,
      refetchOnReconnect: false
    }
  }
});

function App() {

  return (
    <BrowserRouter basename={process.env.PUBLIC_URL}>
      <SnackbarProvider>
        <QueryClientProvider client={queryClient}>
            <ConditionalNavBar />
          <Routes>
            {routes.map((route) => (
              <Route path={route.path} element={route.element} />
            ))}
          </Routes>
        </QueryClientProvider>
      </SnackbarProvider>
    </BrowserRouter>
  );
}

export default App;
