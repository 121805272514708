import '@sendbird/uikit-react/dist/index.css';

import React, {
  useEffect,
  useState,
} from 'react';

import { statusUI } from 'components/OrderCard';
import ScannerDialog from 'components/ScannerDialog';
import {
  IOrderGet,
  OrderStatus,
} from 'interfaces';
import QRCode from 'qrcode.react';
import { useQuery } from 'react-query';
import {
  useNavigate,
  useParams,
} from 'react-router-dom';
import {
  getChatUserID,
  getOrder,
} from 'service';

import CheckIcon from '@mui/icons-material/Check';
import EditIcon from '@mui/icons-material/Edit';
import {
  Button,
  Grid,
  TextField,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import Card from '@mui/material/Card';
import IconButton from '@mui/material/IconButton';
import SBConversation from '@sendbird/uikit-react/GroupChannel';
import SBProvider from '@sendbird/uikit-react/SendbirdProvider';

import { usePatchOrderMutation } from '../components/Order/controller';
import {
  StyledButton3,
  StyledTypography11,
  StyledTypography12,
  StyledTypography13,
  StyledTypography14,
  StyledTypography15,
  StyledTypography16,
  StyledTypography17,
  StyledTypography19,
  StyledTypography20,
  StyledTypography21,
  StyledTypography22,
} from '../components/Order/style.js';
import {
  daysOfWeek,
  isCustomer,
  isHolder,
  isLoggedIn,
} from '../utils';

const OrderDetails: React.FC = () => {
  if (!isLoggedIn()) {
    window.location.href = `${process.env.PUBLIC_URL}/login`;
  }
  const { orderId } = useParams();

  const [trackingNumber, setTrackingNumber] = React.useState("");
  const [notes, setNotes] = React.useState("");
  const [order, setOrder] = React.useState<IOrderGet | undefined>(undefined);
  const [isTrackingNumberEditing, setIsTrackingNumberEditing] = useState(false);
  const [isCustomNoteEditing, setIsCustomNoteEditing] = useState(false);
  const isInitialMount = React.useRef(true);
  const prevOrderId = React.useRef(orderId);
  const [conversationId, setConversationId] = useState<string | null>(null);
  const [accessToken, setAccessToken] = useState<string | null>(null);
  const [userId, setUserId] = useState<string | null>(null);
  const [showConfirmationCode, setShowConfirmationCode] = useState(false);

  const navigate = useNavigate();

  const {
    data,
    //isLoading,
    //isFetching,
    //error,
    refetch,
  } = useQuery(
    ["order", { orderId }], // queryKey
    getOrder.bind(this, orderId || "undefined"), // queryFn
    {
      enabled: isInitialMount.current,
      onSuccess: (data: any) => {
        if (!isInitialMount.current && prevOrderId.current !== orderId) {
          prevOrderId.current = orderId;
          refetch();
        } else {
          setOrder(data);
          setTrackingNumber(data.order.referenceNo);
          setNotes(data.order.note);
          console.log("order", data.order);
          let id = `customer${data.order.customer._id.toString()}`;
          if (isHolder()) {
            id = `holder${data.order.holder._id.toString()}`;
          }
          setUserId(id);
        }
      },
    }
  );

  useEffect(() => {
    const fetchConversationId = async () => {
      try {
        const result = await getChatUserID(orderId ?? "");
        console.log(`Conversation ID for order ${orderId}:`, conversationId);
        setConversationId(result.conversationId ? result.conversationId : null);
        setAccessToken(result.accessToken ? result.accessToken : null);
      } catch (error) {
        console.error("Error fetching conversation ID:", error);
      }
    };

    fetchConversationId();
  }, []);

  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
    }
  }, []);

  const patchOrderMutation = usePatchOrderMutation();

  /*const handleCancelOrderClick = () => {
    patchOrderMutation.mutateAsync({
      status: OrderStatus.CANCELLED,
      orderId: orderId || "undefined",
    });
    window.location.reload();
  };*/

  const openInMap = () => {
    const latitude = data.order && data.order.address.location.coordinates[1];
    const longitude = data.order && data.order.address.location.coordinates[0];
    const url = `https://www.google.com/maps/search/?api=1&query=${latitude},${longitude}`;
    window.open(url, "_blank");
  };

  const handleVerifyPackageClick = () => {
    navigate(`/verify-package/${orderId}`);
  };

  const handleOrderCompletedClick = () => {
    navigate(`/order-completion/${orderId}`);
  };

  const handlePickup = () => {
    navigate(`/order-completion/rate/${orderId}`);
  };

  const handleTrackingNumberSaveClick = async () => {
    setIsTrackingNumberEditing(false);

    try {
      await patchOrderMutation.mutateAsync({
        orderId: orderId || "undefined",
        tracking_number: trackingNumber,
      });
      setOrder((prevOrder) => ({
        ...prevOrder,
        order: {
          ...prevOrder.order,
          referenceNo: trackingNumber,
        },
      }));

      console.log("Tracking number updated successfully", trackingNumber);
    } catch (error) {
      console.error("Failed to update tracking number", error);
    }
  };

  const handleCustomNoteSaveClick = async () => {
    setIsCustomNoteEditing(false);

    try {
      await patchOrderMutation.mutateAsync({
        orderId: orderId || "undefined",
        notes: notes,
      });
      setOrder((prevOrder) => ({
        ...prevOrder,
        order: {
          ...prevOrder.order,
          note: notes,
        },
      }));
      console.log("Note updated successfully", notes);
    } catch (error) {
      console.error("Failed to update notes", error);
    }
  };

  useEffect(() => {
    if (order) {
      console.log("Updated Order:", order);
    }
  }, [order]);

  const handleTrackingNumberEditClick = () => {
    setIsTrackingNumberEditing(true);
  };
  const handleCustomNoteEditClick = () => {
    setIsCustomNoteEditing(true);
  };

  const apiKey = process.env.REACT_APP_GOOGLE_API_KEY;
  const zoom = 13;
  // const size = "370x235";
  const size = "600x300";
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const isMediumScreen = useMediaQuery(theme.breakpoints.down("md"));
  const statusValueStyle = {
    color: "#ff741e",
    fontWeight: "500",
  };

  const cardStyle = {
    //width: isSmallScreen ? "100%" : isMediumScreen ? "80%" : "80%",
    padding: "20px",
    backgroundColor: "#FAF4EC",
    borderRadius: "15px",
    marginTop: isSmallScreen ? "8%" : "26%",
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.2)",
    height: isSmallScreen ? "300px" : isMediumScreen ? "340px" : "360px",
    width: isSmallScreen ? "250px" : isMediumScreen ? "240px" : "280px",
  };

  const [qrReader, setQrReader] = React.useState(false);
  const handleQrReader = async () => {
    setQrReader(true);
  };

  const APP_ID = "222D2AFC-D7A0-4F26-9333-F677291078F4";
  const renderChat = () => {
    return (
      <SBProvider
        appId={APP_ID}
        userId={userId!}
        accessToken={accessToken!}
        eventHandlers={{
          connection: {
            onFailed(error) {
              setConversationId(null);
            },
          },
        }}
      >
        <SBConversation channelUrl={conversationId!} />
      </SBProvider>
    );
  };
  const renderOrderDetails = (imageSrc) => {
    if (!data || !data.order) {
      return null;
    }
    return (
      <>
        <Grid
          container
          spacing={2}
          sx={{
            marginTop: "20px",
            marginBottom: "25px",
            marginLeft: "50px",
            maxWidth: "95%",
          }}
        >
          <Grid item xs={12} sm={6}>
            <StyledTypography11 gutterBottom>
              Order:{" "}
              {`${data.order && data.order.fromCompany} – #${
                data.order && data.order.referenceNo
              }`}
            </StyledTypography11>
            <StyledTypography12>
              Ordered on:{" "}
              {data.order &&
                data.order.createdAt &&
                new Date(data.order.createdAt).toISOString().split("T")[0]}
            </StyledTypography12>
            <StyledTypography12 variant="body1">
              <span>Status:</span>
              <span style={statusValueStyle}>
                  {" "}
                  {data.order &&
                    statusUI[data.order.status as keyof typeof statusUI]}
              </span>
            </StyledTypography12>
            <StyledTypography12 variant="body1">
              Store: {data.order && data.order.fromCompany}
            </StyledTypography12>
            <StyledTypography12 variant="body1">
              Shipping / Courier:{" "}
              {data.order && data.order.courier ? data.order.courier : "N/A"}
            </StyledTypography12>
            {isCustomer() &&
            data.order &&
            data.order.status === OrderStatus.AWAITING_DELIVERY ? (
              !isTrackingNumberEditing ? (
                <StyledTypography12 variant="body1">
                  Tracking number: {"#" + trackingNumber}{" "}
                  <IconButton onClick={handleTrackingNumberEditClick}>
                    <EditIcon />
                  </IconButton>
                </StyledTypography12>
              ) : (
                <div>
                  <TextField
                    label="Tracking number"
                    size="small"
                    variant="outlined"
                    value={trackingNumber}
                    onChange={(e) => setTrackingNumber(e.target.value)}
                    readOnly={false}
                  />
                  <IconButton onClick={handleTrackingNumberSaveClick}>
                    <CheckIcon />
                  </IconButton>
                </div>
              )
            ) : (
              <StyledTypography12 variant="body1">
                Tracking number: {"#" + (data.order && data.order.referenceNo)}
              </StyledTypography12>
            )}
            <StyledTypography12 variant="body1">
              Estimated Delivery:{" "}
              {data.order &&
                data.order.expectedArrival &&
                new Date(data.order.expectedArrival)
                  .toISOString()
                  .split("T")[0]}
            </StyledTypography12>
            {isCustomer() &&
            data.order &&
            data.order.status === OrderStatus.AWAITING_DELIVERY ? (
              !isCustomNoteEditing ? (
                <StyledTypography12
                  variant="body1"
                  style={{ marginRight: "20%" }}
                >
                  Custom Notes: {notes}
                  <IconButton onClick={handleCustomNoteEditClick}>
                    <EditIcon />
                  </IconButton>
                </StyledTypography12>
              ) : (
                <div>
                  <TextField
                    label="Custom Notes"
                    size="small"
                    variant="outlined"
                    value={notes}
                    onChange={(e) => setNotes(e.target.value)}
                    readOnly={false}
                  />
                  <IconButton onClick={handleCustomNoteSaveClick}>
                    <CheckIcon />
                  </IconButton>
                </div>
              )
            ) : (
              <StyledTypography12 variant="body1">
                Custom Notes: {data.order && data.order.note}
              </StyledTypography12>
            )}
          </Grid>
          <Grid item xs={12} sm={6}>
            <img
              src={
                data.order && data.order.processedImg?.thumbnail
                  ? data.order.processedImg.thumbnail
                  : imageSrc
              }
              alt={
                data.order &&
                data.order.status === OrderStatus.AWAITING_DELIVERY
                  ? "Awaiting Delivery"
                  : "Complete Order"
              }
              style={{
                width: "70%",
                height: "auto",
                marginTop:
                  data.order &&
                  data.order.status === OrderStatus.AWAITING_DELIVERY
                    ? "10%"
                    : "15%",
              }}
            />
          </Grid>
        </Grid>
        {data.order.status === OrderStatus.AWAITING_DELIVERY &&
          isCustomer() &&
          conversationId &&
          userId && (
            <div
              style={{
                marginBottom: "10%",
              }}
            >
              {renderChat()}
            </div>
          )}
      </>
    );
  };

  const renderHolderAwaitingDetails = () => (
    <>
      <Grid
        container
        spacing={2}
        sx={{
          marginBottom: "25px",
          marginLeft: "50px",
        }}
      >
        <Grid item xs={12} sm={6}>
          <StyledTypography12 variant="body1">
            Package / Order Arrived?
          </StyledTypography12>
          <StyledButton3 variant="contained" onClick={handleVerifyPackageClick}>
            Verify Package
          </StyledButton3>
        </Grid>
      </Grid>
      {data.order.status === OrderStatus.AWAITING_DELIVERY &&
        isHolder() &&
        conversationId &&
        userId && (
          <div
            style={{
              marginBottom: "10%",
            }}
          >
            {renderChat()}
          </div>
        )}
    </>
  );

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        padding: "20px",
      }}
    >
      {data &&
      data.order &&
      data.order.status === OrderStatus.READY_FOR_PICKUP ? (
        <div
          style={{
            minWidth: 275,
            maxWidth: isCustomer()
              ? isSmallScreen
                ? 400
                : isMediumScreen
                  ? 750
                  : 1000
              : isHolder()
                ? isSmallScreen
                  ? 400
                  : isMediumScreen
                    ? 650
                    : 750
                : 1000,
          }}
        >
          <Grid
            container
            spacing={2}
            sx={{
              marginTop: "20px",
              marginBottom: "25px",
              marginLeft: isCustomer()
                ? isSmallScreen
                  ? "40px"
                  : isMediumScreen
                    ? "50px"
                    : "80px"
                : isHolder()
                  ? isSmallScreen
                    ? "40px"
                    : isMediumScreen
                      ? "40px"
                      : "20px"
                  : 1000,
            }}
          >
            <Grid item xs={12} sm={6}>
              <Grid container spacing={2} alignItems="center">
                <Grid item>
                  {isCustomer() && (
                    <>
                      <StyledTypography11 gutterBottom>
                        Order:{" "}
                        {`${data.order && data.order.fromCompany} – #${
                          data.order && data.order.referenceNo
                        }`}
                      </StyledTypography11>
                      <StyledTypography20 gutterBottom>
                        READY FOR PICKUP
                      </StyledTypography20>
                    </>
                  )}
                  <Grid container spacing={2} alignItems="center">
                    <Grid item>
                      {isCustomer() && (
                        <StyledButton3
                          variant="contained"
                          onClick={openInMap}
                          style={{ mr: "4px" }}
                        >
                          Get Directions in Maps
                        </StyledButton3>
                      )}
                    </Grid>
                    <Grid item>
                      {isCustomer() && data.order && data.order.holder && (
                        <img
                          src={`https://maps.googleapis.com/maps/api/staticmap?center=${data.order.address.location.coordinates[1]},${data.order.address.location.coordinates[0]}&zoom=${zoom}&size=${size}&key=${apiKey}`}
                          alt="Google Map"
                          style={{
                            maxWidth: isSmallScreen
                              ? "70%"
                              : isMediumScreen
                                ? "90%"
                                : "100%",
                            height: "auto",
                            borderRadius: "10px",
                            marginTop: "10px",
                          }}
                          onClick={openInMap}
                        />
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>

              <Grid
                container
                spacing={2}
                sx={{
                  marginBottom: "25px",
                  marginLeft: "40px",
                }}
              >
                {isHolder() && (
                  <div style={{ textAlign: "left" }}>
                    <StyledTypography11 gutterBottom>
                      Order:{" "}
                      {`${data.order && data.order.fromCompany} – #${
                        data.order && data.order.referenceNo
                      }`}
                    </StyledTypography11>
                    <StyledTypography12>
                      Ordered on:{" "}
                      {data.order &&
                        data.order.createdAt &&
                        new Date(data.order.createdAt)
                          .toISOString()
                          .split("T")[0]}
                    </StyledTypography12>
                    <StyledTypography12 variant="body1">
                      <span>Status:</span>
                      <span style={statusValueStyle}> Customer Arriving</span>
                    </StyledTypography12>
                    <StyledTypography12 variant="body1">
                      Store: {data.order && data.order.fromCompany}
                    </StyledTypography12>
                    <StyledTypography12 variant="body1">
                      Shipping / courier:{" "}
                      {data.order && data.order.courier
                        ? data.order.courier
                        : "N/A"}
                    </StyledTypography12>
                    <StyledTypography12 variant="body1">
                      Tracking number:{" "}
                      {"#" + (data.order && data.order.referenceNo)}{" "}
                    </StyledTypography12>
                    <StyledTypography12
                      variant="body1"
                      style={{ marginRight: "20%" }}
                    >
                      Customer notes:{" "}
                      {data.order && data.order.note ? data.order.note : "N/A"}
                    </StyledTypography12>

                    <StyledTypography19>Customer Arrived?</StyledTypography19>
                    <ScannerDialog open={qrReader} setOpen={setQrReader} />
                    <StyledButton3 variant="contained" onClick={handleQrReader}>
                      Scan QR Code
                    </StyledButton3>
                    <StyledTypography12>or</StyledTypography12>
                    <StyledButton3
                      variant="contained"
                      onClick={handleOrderCompletedClick}
                    >
                      Enter Confirmation Code
                    </StyledButton3>
                  </div>
                )}
              </Grid>

              {isCustomer() && (
                <Grid item xs={12} sm={12}>
                  <Grid container justifyContent="flex-start" spacing={2}>
                    <Grid item sx={{ mt: isSmallScreen ? undefined : "20px" }}>
                      <StyledTypography16 style={{ display: "inline" }}>
                        Holder
                      </StyledTypography16>
                      <StyledTypography17
                        style={{ display: "inline", marginLeft: "6px" }}
                      >
                        Hours
                      </StyledTypography17>
                      {daysOfWeek.map((day) => {
                        const operationHour = data.order.address
                          ? data.order.address.operationHours.find(
                              (hour) =>
                                hour.day.toLowerCase() === day.toLowerCase()
                            )
                          : null;
                        return (
                          <Grid item xs={12} key={day}>
                            <StyledTypography15>
                              {day.substring(0, 3)}:{" "}
                              {operationHour
                                ? `${operationHour.from} - ${operationHour.to}`
                                : "Not Working"}
                            </StyledTypography15>
                          </Grid>
                        );
                      })}
                    </Grid>
                    <Grid item xs={6} sm={5}>
                      <Grid
                        item
                        xs={10}
                        sm={12}
                        sx={{
                          mt: isSmallScreen ? undefined : "20px",
                          ml: isSmallScreen ? undefined : "45px",
                        }}
                      >
                        <StyledTypography16 style={{ display: "inline" }}>
                          Holder
                        </StyledTypography16>
                        <StyledTypography17
                          style={{ display: "inline", marginLeft: "6px" }}
                        >
                          Info
                        </StyledTypography17>

                        <StyledTypography15>
                          {data.order &&
                            "Name: " +
                              data.order.holder?.name.first +
                              " " +
                              data.order.holder?.name.last}
                        </StyledTypography15>
                        <StyledTypography15>
                          {data.order &&
                            data.order.holder?.phone &&
                            "Phone: " + data.order.holder?.phone}
                        </StyledTypography15>
                        <StyledTypography22>
                          {data.order &&
                            "Address: " + data.order.address.formattedAddress}
                        </StyledTypography22>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={10}
                        sx={{
                          mt: isSmallScreen ? undefined : "17px",
                          ml: isSmallScreen ? undefined : "45px",
                        }}
                      >
                        <StyledTypography16 style={{ display: "inline" }}>
                          Holder
                        </StyledTypography16>
                        <StyledTypography17
                          style={{ display: "inline", marginLeft: "6px" }}
                        >
                          Notes
                        </StyledTypography17>

                        <StyledTypography22 variant="body1">
                          {data.order && data.order.note
                            ? data.order.note
                            : "N/A"}
                        </StyledTypography22>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              )}
            </Grid>

            <Grid item xs={12} sm={6}>
              <Grid container justifyContent="flex-start">
                {isCustomer() && (
                  <Grid item>
                    <Card variant="outlined" sx={cardStyle}>
                      <Grid
                        container
                        justifyContent="center"
                        alignItems="center"
                        direction="column"
                      >
                        <Grid item>
                          <StyledTypography13 align="center">
                            {showConfirmationCode
                              ? "Order Confirmation Code"
                              : `Show QR Code to ${data.order.holder?.name.first}`}
                          </StyledTypography13>
                        </Grid>
                        <Grid item>
                          {showConfirmationCode ? (
                            <StyledTypography14 align="center" variant="h4">
                              <b>{data.order.pickupShortCode}</b>
                            </StyledTypography14>
                          ) : (
                            <QRCode value={orderId || ""} size={120} />
                          )}
                        </Grid>
                        <Grid item>
                          <StyledTypography21>
                            {showConfirmationCode
                              ? "Please give code to Holder upon arrival to identify your order"
                              : ""}
                          </StyledTypography21>
                        </Grid>
                        <Grid item>
                          <Grid item>
                            <Button
                              variant="outlined"
                              sx={{
                                fontSize: isSmallScreen
                                  ? "0.53rem"
                                  : isMediumScreen
                                    ? "0.75rem"
                                    : "0.9rem",
                              }}
                              onClick={() =>
                                setShowConfirmationCode(!showConfirmationCode)
                              }
                            >
                              {showConfirmationCode
                                ? "Show QR Code instead"
                                : "Show confirmation code instead"}
                            </Button>
                          </Grid>
                        </Grid>
                        <Grid item>
                          <Grid item>
                            <Button
                              variant="contained"
                              onClick={handlePickup}
                              sx={{
                                background: "#ff741e ",
                                color: "#ffffff",
                                "&:hover": {
                                  backgroundColor: "#FF9858 rtant",
                                },
                                fontWeight: "500 rtant",
                                fontSize: isSmallScreen
                                  ? "0.53rem"
                                  : isMediumScreen
                                    ? "0.8rem"
                                    : "0.9rem",
                                width: "auto ",
                                boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.4) ",
                                fontFamily: "Rubik, sans-serif",
                                marginTop: "18px",
                                borderRadius: "5px ",
                              }}
                            >
                              I Picked Up
                            </Button>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Card>
                  </Grid>
                )}

                {isHolder() && (
                  <img
                    src={
                      data.order && data.order.processedImg?.thumbnail
                        ? data.order.processedImg.thumbnail
                        : `${process.env.PUBLIC_URL}/card_arriving_order.png`
                    }
                    alt={"Customer Arriving"}
                    style={{
                      width: "70%",
                      height: "auto",
                      marginTop: isSmallScreen ? "0%" : "15%",
                    }}
                  />
                )}
              </Grid>

              {isCustomer() && (
                <Grid
                  item
                  xs={12}
                  sm={8}
                  justifyContent="flex-start"
                  sx={{
                    mt: isSmallScreen ? "10%" : "11%",
                    ml: isSmallScreen
                      ? "-1%"
                      : isMediumScreen
                        ? undefined
                        : "-11%",
                    textAlign: isSmallScreen ? "left" : "right",
                  }}
                >
                  <StyledTypography12>
                    Ordered on:{" "}
                    {data.order &&
                      data.order.createdAt &&
                      new Date(data.order.createdAt)
                        .toISOString()
                        .split("T")[0]}
                  </StyledTypography12>
                  <StyledTypography12 variant="body1">
                    <span>Status:</span>
                    <span style={statusValueStyle}>
                      {" "}
                      {data.order &&
                        statusUI[data.order.status as keyof typeof statusUI]}
                    </span>
                  </StyledTypography12>
                  <StyledTypography12 variant="body1">
                    Store: {data.order && data.order.fromCompany}
                  </StyledTypography12>
                  <StyledTypography12 variant="body1">
                    Shipping / courier:{" "}
                    {data.order && data.order.courier
                      ? data.order.courier
                      : "N/A"}
                  </StyledTypography12>
                  <StyledTypography12 variant="body1">
                    Tracking number:{" "}
                    {"#" + (data.order && data.order.referenceNo)}{" "}
                  </StyledTypography12>

                  <StyledTypography12
                    variant="body1"
                    style={{ marginRight: isSmallScreen ? "20%" : undefined }}
                  >
                    Customer notes:{" "}
                    {data.order && data.order.note ? data.order.note : "N/A"}
                  </StyledTypography12>
                </Grid>
              )}
            </Grid>
          </Grid>
          {conversationId && userId && (
            <div
              style={{
                marginTop: "10%",
                marginBottom: "10%",
              }}
            >
              {renderChat()}
            </div>
          )}
        </div>
      ) : data &&
        data.order &&
        (data.order.status === OrderStatus.AWAITING_DELIVERY ||
          data.order.status === OrderStatus.PAYMENTMETHOD_REQUIRED ||
          data.order.status === OrderStatus.INDELIVERY) ? (
        <div
          style={{
            minWidth: 275,
            maxWidth: isSmallScreen ? 400 : isMediumScreen ? 650 : 800,
          }}
        >
          {renderOrderDetails(
            `${process.env.PUBLIC_URL}/card_awaiting_delivery_order.png`
          )}
          {isHolder() && renderHolderAwaitingDetails()}
        </div>
      ) : (
        <div
          style={{
            minWidth: 275,
            maxWidth: isSmallScreen ? 400 : isMediumScreen ? 650 : 800,
          }}
        >
          {isCustomer() &&
            renderOrderDetails(
              `${process.env.PUBLIC_URL}/delivery_package.png`
            )}
          {isHolder() &&
            renderOrderDetails(
              `${process.env.PUBLIC_URL}/card_completed_order.png`
            )}
        </div>
      )}
    </div>
  );
};

export default OrderDetails;
