import { IAddress, IAddressPatch, IAddressPost, IHolder } from "interfaces";
import { dataProvider } from "api";
import {
  holder_suggestions_path,
  user_me_address,
  user_me_addresses,
  user_addresses,

} from "service";

export const getUserAddresses = async (): Promise<{ addresses: IAddress[] }> =>
  dataProvider.get(user_me_addresses()).json();

export const getHolderSuggestions = async (
  lat: number,
  lon: number
): Promise<{ addresses: IHolder[] }> => {
  try {
    const response: { addresses: IHolder[] } = await dataProvider.get(holder_suggestions_path(lat, lon)).json();
    console.log("API Response:", response);
      console.log("Holder suggestions:", response.addresses);
    return response;
  } catch (error) {
    console.error("Error fetching holder suggestions:", error);
    throw error;
  }
};

/*export const getHolderSuggestions = async (
  userCoordinates: { latitude: number; longitude: number } | "undefined",
): Promise<{ results: IHolder[] }> => {
  if (userCoordinates === "undefined") {
    return { results: [] };
  }
  const { latitude, longitude } = userCoordinates;
  
  return dataProvider.get(holder_suggestions_path({ latitude, longitude })).json();
};*/


export const postUserAddress = async ({
  user,
  name,
  street,
  type,
  city,
  province,
  country,
  postal,
  latitude,
  longitude,
  operationHours,
  formattedAddress,
 locationNote,
 googlePlaceId,
 isHolder,
 maxWeight,
 maxTotalLength,
 maxHoldingDays,
}: IAddressPost): Promise<Response> => {
  
  const body: any = {
    address: {
      user,
      type,
      name,
      street,
      city,
      province,
      country,
      postal,
      location: {
        type: "Point",
        coordinates: [longitude, latitude],
      },
      operationHours,
      formattedAddress,
      locationNote,
      googlePlaceId,
      isHolder,
      maxWeight,
      maxTotalLength,
      maxHoldingDays
    },
  };

  try {
    return dataProvider.post(user_addresses(), {
      json: body,
    });
  } catch (error) {
    throw error;
  }
};

export const patchUserAddress = async ({
  addressId,
  is_primary,
}: IAddressPatch): Promise<Response>=> {
  const body: any = {
    address: {
      isDefault: is_primary,
      id: addressId,
    },
  };
  dataProvider.put(user_me_address(addressId), {
     json: body,
  });
};

export const deleteUserAddress = async (addressId: string): Promise<IAddress> =>{
  const body: any = {
    address: {    
      id: addressId,
    },
  };
  dataProvider.delete(user_me_address(addressId), {
     json: body,
  });
};
  
