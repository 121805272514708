import React, { useEffect, useState } from "react";

import { StaticMap } from "components/Map";
import { SuccessDialog } from "components/SuccessDialog";
import copy from "copy-to-clipboard";
import get from "lodash/get";
import { useMutation, useQuery } from "react-query";
import { getHolderSuggestions, postOrder } from "service";
import { userEstimate } from "service/requests";

import { IHolder, IOrderPost } from "@interfaces";
import CloseIcon from "@mui/icons-material/Close";
import StarIcon from "@mui/icons-material/Star";
import {
  Box,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  Grid,
  Link,
  Radio,
  RadioGroup,
  Select,
  TextField,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import MenuItem from "@mui/material/MenuItem";
import Typography from "@mui/material/Typography";
import { makeStyles } from "@mui/styles";

import { daysOfWeek } from "../../utils";
import {
  StyledButton1,
  StyledButton2,
  StyledTypography,
  StyledTypography10,
  StyledTypography2,
  StyledTypography3,
  StyledTypography4,
  StyledTypography5,
  StyledTypography6,
  StyledTypography7,
  StyledTypography8,
  StyledTypography9,
} from "./style.js";

const useStyles = makeStyles((theme) => ({
  sendPackage: {
    "& .send": {
      fontFamily: "Rubik, sans-serif",
      fontWeight: 450,
      fontSize: "1.9rem",
      color: "#ff741e",
      "@media (max-width: 768px)": {
        fontSize: "1.5rem",
      },
      "@media (max-width: 480px)": {
        fontSize: "1.2rem",
      },
    },

    "& .package": {
      fontFamily: "Rubik, sans-serif",
      fontWeight: 450,
      fontSize: "1.9rem",
      color: "primary",
      "@media (max-width: 768px)": {
        fontSize: "1.5rem",
      },
      "@media (max-width: 480px)": {
        fontSize: "1.2rem",
      },
    },
  },
  label: {
    fontSize: "17px",
    color: "primary",
    "@media (max-width: 768px)": {
      fontSize: "1rem",
    },
    "@media (max-width: 480px)": {
      fontSize: "0.8rem",
    },
  },
  checkbox: {
    fontSize: "16px",
    color: "primary",
    "@media (max-width: 768px)": {
      fontSize: "1rem",
    },
    "@media (max-width: 480px)": {
      fontSize: "0.8rem",
    },
  },
}));

const getUserLocation = async () => {
  try {
    const position = await new Promise<GeolocationPosition>(
      (resolve, reject) => {
        navigator.geolocation.getCurrentPosition(
          (position) => resolve(position),
          (error) => reject(error)
        );
      }
    );
    console.log("User location obtained:", position.coords);

    return position.coords;
  } catch (error) {
    console.error("Error getting user location:", error);
    throw error;
  }
};

const OrderCreationDialog: React.FC<{
  openStep1: boolean;
  setOpenStep1: any;
  openStep2: boolean;
  setOpenStep2: any;
  openStep3: boolean;
  setOpenStep3: any;
}> = ({
  openStep1,
  setOpenStep1,
  openStep2,
  setOpenStep2,
  openStep3,
  setOpenStep3,
}) => {
  const theme = useTheme();
  const classes = useStyles();

  const handleClose = (isLastStep = false) => {
    setOpenStep1(false);
    setOpenStep2(false);
    setOpenStep3(false);
    if (isLastStep) {
      window.location.reload();
    }
  };

  const handleNextStep = () => {
    setOpenStep1(false);
    setOpenStep2(true);
  };

  const handlePreviousStep = () => {
    setOpenStep1(true);
    setOpenStep2(false);
  };

  const postOrderMutation = useMutation<Response, Error, IOrderPost>(
    postOrder,
    {
      onSuccess: (_data, variables) => {
        setOpenStep2(false);
        setOpenStep3(true);
      },
      onError: (error) => {},
    }
  );

  const handleConfirm = () => {
    if (
      isChecked &&
      companyName.trim() !== "" &&
      !isNaN(parseFloat(quantity)) &&
      parseFloat(quantity) > 0
    ) {
      let selectedExpectedArrival = new Date();
      const sameNextDay = document.getElementById("sameNextDay");
      const twoToSixDays = document.getElementById("2-6days");
      const sevenToTwelveDays = document.getElementById("7-12days");
      const twelvePlusDays = document.getElementById("12+days");
      let currentDate = new Date();

      if (sameNextDay && (sameNextDay as HTMLInputElement).checked) {
        selectedExpectedArrival = new Date(
          currentDate.getTime() + 24 * 60 * 60 * 1000
        );
      } else if (twoToSixDays && (twoToSixDays as HTMLInputElement).checked) {
        selectedExpectedArrival = new Date(
          currentDate.getTime() + 6 * 24 * 60 * 60 * 1000
        ); // Add 6 days
      } else if (
        sevenToTwelveDays &&
        (sevenToTwelveDays as HTMLInputElement).checked
      ) {
        selectedExpectedArrival = new Date(
          currentDate.getTime() + 12 * 24 * 60 * 60 * 1000
        );
      } else if (
        twelvePlusDays &&
        (twelvePlusDays as HTMLInputElement).checked
      ) {
        selectedExpectedArrival = new Date(
          currentDate.getTime() + 13 * 24 * 60 * 60 * 1000
        );
      }

      console.log("Data to be sent to API:", {
        store: companyName,
        carrier: shippingInfo,
        tracking_number: trackingNumber,
        expectedArrival: selectedExpectedArrival.toISOString(),
        address: "",
        package_size: isLarge ? 16 : 15,
        notes: "",
      });

      postOrderMutation.mutateAsync({
        store: companyName,
        carrier: shippingInfo,
        tracking_number: trackingNumber,
        expectedArrival: selectedExpectedArrival.toISOString(),
        address: "",
        package_size: isLarge ? 16 : 15,
        notes: "",
      });
    } else {
      alert("Please fill in all the required fields before confirming.");
    }
  };

  const [isChecked, setIsChecked] = React.useState(false);

  const handleCheckboxChange = (event: any) => {
    setIsChecked(event.target.checked);
  };

  const [companyName, setCompanyName] = React.useState("");
  const [quantity, setQuantity] = React.useState("");
  const [shippingInfo, setShippingInfo] = React.useState("");
  const [trackingNumber, setTrackingNumber] = React.useState("");
  const [notes, setNotes] = React.useState("");
  const [holderSuggestions, setHolderSuggestions] = React.useState([]);
  const [isLarge, setIsLarge] = React.useState(false);
  const [expectedArrival, setExpectedArrival] = useState("");
  const [quantityError, setQuantityError] = useState("");
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [requiredFieldsComplete, setRequiredFieldsComplete] = useState(false);
  const [estimateData, setEstimateData] = useState<any>(null);
  const [addressId, setAddressId] = useState<string | null>(null);
  const [userLocation, setUserLocation] = React.useState({ lat: 0, lon: 0 });

  const roundedInputStyle = {
    borderRadius: 10,
    border: "0.1px solid black",
    backgroundColor: "#f8f8f8",
    fontSize: "16.2px",
    color: "primary",
  };

  if (window.innerWidth <= 768) {
    roundedInputStyle.fontSize = "0.9rem";
  }
  if (window.innerWidth <= 414) {
    roundedInputStyle.fontSize = "0.76rem";
  }

  const requiredIconStyle = {
    color: "primary",
    fontSize: "0.4rem",
    verticalAlign: "top",
  };

  const apiKey = "AIzaSyD4zoMIosG6-lPGt8__7LvwiXJoeosN5RU";
  const zoom = 13;

  //const userAddressId = getCurrentUser()?.address?.id;

  /*const [userCoordinates, setUserCoordinates] = useState(null);

  useEffect(() => {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        const { latitude, longitude } = position.coords;
        setUserCoordinates({ latitude, longitude });
      },
      (error) => {
        console.error("Error getting user coordinates:", error);
      }
    );
  }, []); */

  React.useEffect(() => {
    async function fetchData() {
      try {
        const location = await getUserLocation();
        setUserLocation({ lat: location.latitude, lon: location.longitude });

        const results = await getHolderSuggestions(
          userLocation.lat,
          userLocation.lon
        );
        if (results && results.addresses.length) {
          setHolderSuggestions(results.addresses);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }

    fetchData();
  }, [userLocation.lat, userLocation.lon]);

  const {
    data: holderAddressSuggestionsResults = { results: [] },
    isLoading,
    isFetching,
    error,
  } = useQuery(
    [{ lat: userLocation.lat, lon: userLocation.lon }],
    getHolderSuggestions.bind(this, userLocation.lat, userLocation.lon)
  );
  if (error) {
    console.error("Error fetching holder suggestions:", error);
  }

  if (
    !isLoading &&
    !isFetching &&
    !error &&
    holderAddressSuggestionsResults &&
    holderAddressSuggestionsResults.results &&
    holderAddressSuggestionsResults.results.length &&
    !holderSuggestions.length
  ) {
    setHolderSuggestions(holderAddressSuggestionsResults || []);
  } else {
    console.error("Error in handling suggestions:", error);
  }

  const [selectedHolder, setSelectedHolder] = React.useState<IHolder | null>(
    null
  );

  useEffect(() => {
    if (selectedHolder && selectedHolder._id) {
      setAddressId(selectedHolder._id);
    } else {
      setAddressId(null);
    }
  }, [selectedHolder]);

  useEffect(() => {
    const fetchEstimate = async () => {
      try {
        if (addressId) {
          const data = await userEstimate(addressId);
          setEstimateData(data);
          console.log("Fetched user estimate data:", data);
        }
      } catch (error) {
        console.error("Error fetching estimate:", error);
      }
    };

    if (isLarge || !isLarge) {
      fetchEstimate();
    }
  }, [isLarge, addressId]);

  const copyToClipboard = (value: string) => {
    copy(value);
    alert(`You have copied "${value}"`);
  };
  const handleFieldClick = (field: string) => {
    if (selectedHolder) {
      const valueToCopy = get(selectedHolder, field) || "N/A";
      copyToClipboard(valueToCopy);
    }
  };

  const handleQuantityChange = (event) => {
    const inputQuantity = event.target.value;

    if (!isNaN(inputQuantity) && inputQuantity <= 3 && inputQuantity >= 0) {
      setQuantity(inputQuantity);
      setQuantityError("");
    } else if (inputQuantity < 0) {
      setQuantityError("Quantity should be a positive number.");
    } else {
      setQuantity(inputQuantity);
      setQuantityError("Quantity should be 3 or less.");
    }
  };

  useEffect(() => {
    const isComplete: boolean =
      companyName !== "" &&
      quantity.toString() !== "" &&
      parseFloat(quantity) > 0;
    setRequiredFieldsComplete(isComplete);
  }, [companyName, quantity]);

  const isConfirmDisabled =
    !requiredFieldsComplete ||
    parseFloat(quantity) > 3 ||
    !isChecked ||
    parseFloat(quantity) <= 0;

  const debug = () => {
    console.log("selectedHolder", selectedHolder);
    console.log("selectedHolderHour", selectedHolder?.operationHours);
    console.log("holderSuggestions", holderSuggestions);
    console.log("companyName", companyName);
    console.log("package_size", isLarge);
    console.log("quantity", quantity);
    console.log("shippingInfo", shippingInfo);
    console.log("trackingNumber", trackingNumber);
    console.log("notes", notes);
    console.log("expectedArrival: ", expectedArrival);
  };
  debug();

  /*function convertToAMPM(time) {
    const [hours, minutes] = time.split(":");
    let ampm = "AM";

    let formattedHours = parseInt(hours);
    if (formattedHours >= 12) {
      ampm = "PM";
      if (formattedHours > 12) {
        formattedHours -= 12;
      }
    }

    return `${formattedHours}:${minutes}${ampm}`;
  }*/

  return (
    <>
      <Dialog open={openStep1} onClose={handleClose}>
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <IconButton onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </div>
        <DialogTitle className={classes.sendPackage} align="center">
          <span className="send">Send</span>
          <span className="package">Package</span>
        </DialogTitle>
        <StyledTypography align="center">Step 1 of 2</StyledTypography>
        <DialogContent>
          <StyledTypography2>Select a nearby Holder</StyledTypography2>
          <StyledTypography3>
            (you can add or change your current location in Account settings)
          </StyledTypography3>
          <Select
            labelId="select-holder-label"
            id="select-holder-id"
            label="Select holder"
            value={selectedHolder ? selectedHolder._id : ""}
            onChange={(event) => {
              const selectedHolder = holderSuggestions.find(
                (item) => item._id === event.target.value
              );
              setSelectedHolder(selectedHolder || null);
            }}
            sx={{
              marginBottom: "10px",
              marginTop: "10px",
              "@media (max-width: 768px)": {
                fontSize: "0.85rem",
              },
              "@media (max-width: 480px)": {
                fontSize: "0.75rem",
              },
            }}
          >
            {holderSuggestions.map((item) => (
              <MenuItem key={item._id} value={item._id}>
                {item.name}, {item.street}, {item.city}
              </MenuItem>
            ))}
          </Select>

          {selectedHolder && (
            <>
              <div
                style={{
                  display: "flex",
                  flexDirection: isSmallScreen ? "column" : "row",
                }}
              >
                <div
                  style={{
                    textAlign: "left",
                    marginTop: "15px",
                    marginBottom: "19px",
                  }}
                >
                  <StaticMap
                    size={"285x285"}
                    latitude={selectedHolder.location.coordinates[1]}
                    longitude={selectedHolder.location.coordinates[0]}
                  />
                </div>
                <div>
                  <StyledTypography4 variant="h6">
                    <span style={{ color: "#ff741e" }}>Holder </span>
                    <span style={{ color: "primary" }}>Hours </span>
                  </StyledTypography4>
                  {daysOfWeek.map((day, index) => {
                    const capitalizedDay =
                      day.charAt(0).toUpperCase() + day.slice(1);
                    const dayInfo = selectedHolder.operationHours.find(
                      (item) => item.day.toLowerCase() === day.toLowerCase()
                    );
                    const formatTime = (time) =>
                      new Date(0, 0, 0, time, 0).toLocaleTimeString("en-US", {
                        hour: "2-digit",
                        minute: "2-digit",
                        hour12: false,
                      });
                    return (
                      <Grid item xs={12} key={index}>
                        <StyledTypography5>
                          {capitalizedDay}:{" "}
                          {dayInfo && dayInfo.from !== -1 && dayInfo.to !== -1
                            ? `${formatTime(dayInfo.from)} - ${formatTime(
                                dayInfo.to
                              )}`
                            : "Not Working"}
                        </StyledTypography5>
                      </Grid>
                    );
                  })}
                </div>
              </div>
              <StyledTypography6>
                <span style={{ color: "#ff741e" }}>Copy and paste </span>
                <span style={{ color: "primary" }}>
                  the above Holder address into the 'Shipping Address' of your
                  order, then complete your purchase. When you've completed your
                  order, return here and click 'Next Step'.{" "}
                </span>
              </StyledTypography6>
              <Grid
                container
                justifyContent="center"
                alignItems="center"
                sx={{
                  padding: 5,
                  "@media (max-width: 768px)": {
                    padding: 0,
                  },
                  "@media (max-width: 480px)": {
                    padding: 0,
                  },
                }}
              >
                <Grid item xs={12}>
                  <FormControl fullWidth>
                    <Box display="flex" alignItems="center">
                      <div style={{ width: "35%" }}>
                        <label
                          htmlFor="street-address"
                          className={classes.label}
                        >
                          Street Address:
                        </label>
                      </div>
                      <div style={{ width: "65%" }}>
                        <TextField
                          id="street-address"
                          value={
                            (selectedHolder
                              ? get(selectedHolder, "name") || "N/A"
                              : "N/A") as string
                          }
                          fullWidth
                          multiline
                          size="medium"
                          helperText="click to copy address"
                          color="secondary"
                          InputProps={{
                            style: roundedInputStyle,
                            readOnly: true,
                            onClick: () => handleFieldClick("name"),
                          }}
                        />
                      </div>
                    </Box>
                  </FormControl>
                </Grid>
                <Grid item xs={12} style={{ marginTop: 20 }}>
                  <FormControl fullWidth>
                    <Box display="flex" alignItems="center">
                      <div style={{ width: "35%" }}>
                        <label htmlFor="address-2" className={classes.label}>
                          Unit:
                        </label>
                      </div>
                      <div style={{ width: "65%" }}>
                        <TextField
                          id="address-2"
                          value={
                            (selectedHolder
                              ? get(selectedHolder, "street") || "N/A"
                              : "N/A") as string
                          }
                          fullWidth
                          multiline
                          size="medium"
                          helperText="click to copy address"
                          color="secondary"
                          InputProps={{
                            style: roundedInputStyle,
                            readOnly: true,
                            onClick: () => handleFieldClick("street"),
                          }}
                        />
                      </div>
                    </Box>
                  </FormControl>
                </Grid>
                <Grid item xs={12} style={{ marginTop: 20 }}>
                  <FormControl fullWidth>
                    <Box display="flex" alignItems="center">
                      <div style={{ width: "35%" }}>
                        <label htmlFor="zip-code" className={classes.label}>
                          Postal Code:
                        </label>
                      </div>
                      <div style={{ width: "65%" }}>
                        <TextField
                          id="zip-code"
                          value={
                            (selectedHolder
                              ? get(selectedHolder, "postal") || "N/A"
                              : "N/A") as string
                          }
                          fullWidth
                          multiline
                          size="medium"
                          helperText="click to copy address"
                          color="secondary"
                          InputProps={{
                            style: roundedInputStyle,
                            readOnly: true,
                            onClick: () => handleFieldClick("postal"),
                          }}
                        />
                      </div>
                    </Box>
                  </FormControl>
                </Grid>
                <Grid item xs={12} style={{ marginTop: 20 }}>
                  <FormControl fullWidth>
                    <Box display="flex" alignItems="center">
                      <div style={{ width: "35%" }}>
                        <label htmlFor="city" className={classes.label}>
                          City / Town:
                        </label>
                      </div>
                      <div style={{ width: "65%" }}>
                        <TextField
                          id="city"
                          value={
                            (selectedHolder
                              ? get(selectedHolder, "city") || "N/A"
                              : "N/A") as string
                          }
                          fullWidth
                          multiline
                          size="medium"
                          helperText="click to copy address"
                          color="secondary"
                          InputProps={{
                            style: roundedInputStyle,
                            readOnly: true,
                            onClick: () => handleFieldClick("city"),
                          }}
                        />
                      </div>
                    </Box>
                  </FormControl>
                </Grid>
              </Grid>
            </>
          )}
        </DialogContent>
        <DialogActions>
          <StyledButton1 onClick={handleNextStep} disabled={!selectedHolder}>
            Next Step
          </StyledButton1>
        </DialogActions>
      </Dialog>

      <Dialog open={openStep2} onClose={handleClose}>
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <IconButton onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </div>
        <DialogTitle className={classes.sendPackage} align="center">
          <span className="send">Send</span>
          <span className="package">Package</span>
        </DialogTitle>
        <StyledTypography align="center">Step 2 of 2</StyledTypography>
        <DialogContent>
          <TextField
            required
            autoFocus
            margin="dense"
            id="name"
            label="Which company is this package from?"
            type="string"
            fullWidth
            variant="standard"
            helperText="e.g. Amazon, Best Buy, etc."
            value={companyName}
            sx={{ marginBottom: "10px" }}
            InputLabelProps={{
              shrink: true,
            }}
            onChange={(event) => setCompanyName(event.target.value)}
          />
          {/*} <div style={{ display: "flex", alignItems: "center" }}>
            <TextField
              required
              margin="dense"
              id="number"
              label="Quantity of items (max 3 items):"
              type="number"
              fullWidth
              variant="standard"
              value={quantity}
              onChange={handleQuantityChange}
              sx={{ marginBottom: "25px" }}
              InputLabelProps={{
                shrink: true,
              }}
            />
            {quantityError && (
              <p style={{ color: "#ff741e", marginLeft: "10px" }}>
                {quantityError}
              </p>
            )}
            </div>*/}
          <Typography variant="body2">
            Approximate order size? <StarIcon style={requiredIconStyle} />
          </Typography>
          <RadioGroup aria-label="package-size" name="package-size">
            <FormControlLabel
              value="small"
              control={<Radio />}
              label={
                <StyledTypography7>
                  Small package (≤ 35 lbs or 16 kgs)
                </StyledTypography7>
              }
              checked={!isLarge}
              onChange={() => setIsLarge(false)}
            />
            <FormControlLabel
              value="large"
              control={<Radio />}
              label={
                <StyledTypography7>
                  Large package (<span>&gt;</span> 35 lbs or 16 kgs)
                </StyledTypography7>
              }
              checked={isLarge}
              onChange={() => setIsLarge(true)}
            />
          </RadioGroup>
          <StyledTypography8>
            Holders have the option to leave packages over 35 lbs or 16 kgs
            outside. Examples of 35lbs or more: desktop computer, bag of cement,
            car battery, baby stroller.
          </StyledTypography8>
          <StyledTypography9>
            Additional <strong>optional</strong> package information:
          </StyledTypography9>
          <TextField
            margin="dense"
            id="name"
            label="Shipping / courier"
            type="string"
            fullWidth
            variant="standard"
            value={shippingInfo}
            onChange={(event) => setShippingInfo(event.target.value)}
            InputLabelProps={{
              shrink: true,
            }}
          />
          <TextField
            margin="dense"
            id="name"
            label="Order or reference #"
            type="string"
            fullWidth
            variant="standard"
            value={trackingNumber}
            onChange={(event) => setTrackingNumber(event.target.value)}
            InputLabelProps={{
              shrink: true,
            }}
          />
          <Typography style={{ fontSize: "11px", marginTop: "10px" }}>
            Expected Arrival
          </Typography>
          <RadioGroup aria-label="expected-arrival" name="expected-arrival">
            <FormControlLabel
              value="sameNextDay"
              control={<Radio />}
              label={<StyledTypography7>Same/Next Day</StyledTypography7>}
              checked={expectedArrival === "sameNextDay"}
              onChange={() => setExpectedArrival("sameNextDay")}
            />
            <FormControlLabel
              value="2-6days"
              control={<Radio />}
              label={<StyledTypography7>2-6 Days</StyledTypography7>}
              checked={expectedArrival === "2-6days"}
              onChange={() => setExpectedArrival("2-6days")}
            />
            <FormControlLabel
              value="7-12days"
              control={<Radio />}
              label={<StyledTypography7>7-12 Days</StyledTypography7>}
              checked={expectedArrival === "7-12days"}
              onChange={() => setExpectedArrival("7-12days")}
            />
            <FormControlLabel
              value="12+days"
              control={<Radio />}
              label={<StyledTypography7>12+ Days</StyledTypography7>}
              checked={expectedArrival === "12+days"}
              onChange={() => setExpectedArrival("12+days")}
            />
          </RadioGroup>
          <TextField
            margin="dense"
            id="name"
            label="Additional notes for Holder"
            type="string"
            fullWidth
            variant="standard"
            value={notes}
            onChange={(event) => setNotes(event.target.value)}
            InputLabelProps={{
              shrink: true,
            }}
          />
          {estimateData && (
            <div>
              <Typography variant="body2" style={{ marginTop: "10px" }}>
                Estimated Total
              </Typography>
              <Typography style={{ fontSize: "11px", marginTop: "10px" }}>
                Regular delivery: $
                {Number.isInteger(estimateData.estimate.delivery)
                  ? estimateData.estimate.delivery
                  : estimateData.estimate.delivery.toFixed(2)}{" "}
                <br />
                Third-party processing fee: $
                {Number.isInteger(estimateData.estimate["3rdParty"])
                  ? estimateData.estimate["3rdParty"]
                  : estimateData.estimate["3rdParty"].toFixed(2)}{" "}
                <br />
                Administrative fee: $
                {Number.isInteger(estimateData.estimate.admin)
                  ? estimateData.estimate.admin
                  : estimateData.estimate.admin.toFixed(2)}{" "}
                <br />
                Total: $
                {Number.isInteger(estimateData.estimate.total)
                  ? estimateData.estimate.total
                  : estimateData.estimate.total.toFixed(2)}
              </Typography>
            </div>
          )}

          <FormControlLabel
            sx={{
              marginTop: "30px",
              marginBottom: "20px",
            }}
            control={
              <Checkbox
                checked={isChecked}
                onChange={handleCheckboxChange}
                color="primary"
              />
            }
            label={
              <StyledTypography10>
                I agree to the <Link href="#">Terms of Use</Link>, which
                includes agreeing to not ship anything illegal, prohibited, or
                hazardous to this location. For more information on prohibited
                items, <Link href="#">click here</Link>.
              </StyledTypography10>
            }
          />
        </DialogContent>
        <DialogActions>
          <StyledButton2 onClick={handlePreviousStep}>
            Previous Step
          </StyledButton2>
          <Button
            variant="contained"
            sx={{
              background: isChecked ? "#ff741e" : "#ffffff",
              color: isChecked ? "#ffffff" : "#ff741e",
              "&:hover": {
                backgroundColor: "#FF9858",
              },
              fontWeight: 500,
              fontSize: "0.95rem",
              width: "30%",
              boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.4)",
              fontFamily: "Rubik, sans-serif",
              marginBottom: "30px",
              marginRight: "18px",
              borderRadius: "5px",
              "@media (max-width: 768px)": {
                fontSize: "0.8rem",
                marginTop: "5px",
                width: "30%",
              },
              "@media (max-width: 480px)": {
                fontSize: "0.53rem",
                marginTop: "0px",
                width: "36%",
              },
            }}
            onClick={handleConfirm}
            color={!isConfirmDisabled ? "primary" : "secondary"}
            disabled={isConfirmDisabled}
          >
            Confirm & Send
          </Button>
        </DialogActions>
      </Dialog>
      <SuccessDialog
        open={openStep3}
        handleClose={handleClose}
        dialogTitle={"Order Submitted"}
        dialogText={
          "The Holder has been notified of your delivery and is now expecting your package. When the package arrives, the Holder will process the package and you will be notified."
        }
        buttonText={"Return to main menu"}
        imgSrc={`${process.env.PUBLIC_URL}/order_submitted.png`}
      />
    </>
  );
};

export { OrderCreationDialog, useStyles };
