import React from 'react';

export const StaticMap: React.FC<{
  latitude: number;
  longitude: number;
  size: string;
}> = ({ latitude, longitude, size }) => {
  const apiKey = process.env.REACT_APP_GOOGLE_API_KEY;

  const zoom = 13;

  return (
    <a
      href={`https://www.google.com/maps/search/?api=1&query=${latitude},${longitude}`}
      target="_blank"
      rel="noopener noreferrer"
    >
      <img
        src={`https://maps.googleapis.com/maps/api/staticmap?center=${latitude},${longitude}&zoom=${zoom}&size=${size}&key=${apiKey}`}
        alt="Google Map"
      />
    </a>
  );
};
