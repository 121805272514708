import React from 'react';
import { Box, Typography, SvgIcon } from '@mui/material';

const OversizedPackageAccepted: React.FC = () => {
  return (
    <Box display="flex" alignItems="center">
      <Box
        sx={{
          backgroundColor: 'rgb(28,45,74)',
          borderRadius: '50%',
          p: 1,
          display: 'inline-flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <SvgIcon
          style={{ width: 24, height: 24, fill: 'white' }}
          aria-label="Resize Icon"
        >
          {/* Replace the following path with your actual SVG path data */}
          <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#e8eaed"><path d="M760-600v-160H600v-80h240v240h-80ZM120-120v-240h80v160h160v80H120Zm0-320v-80h80v80h-80Zm0-160v-80h80v80h-80Zm0-160v-80h80v80h-80Zm160 0v-80h80v80h-80Zm160 640v-80h80v80h-80Zm0-640v-80h80v80h-80Zm160 640v-80h80v80h-80Zm160 0v-80h80v80h-80Zm0-160v-80h80v80h-80Zm0-160v-80h80v80h-80Z"/></svg>
        </SvgIcon>
      </Box>
      <Typography variant="body1" sx={{ ml: 1 }}>
        Oversized package accepted
      </Typography>
    </Box>
  );
};

export default OversizedPackageAccepted;
