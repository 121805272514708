import React from "react";
import { styled } from "@mui/material/styles";
import WarningIcon from "@mui/icons-material/Warning";
const PREFIX = "FormFieldError";
const classes = {
  error: `${PREFIX}-error`,
  errorText: `${PREFIX}-errorText`,
};
const Root = styled("span")(() => ({
  [`&.${classes.error}`]: {
    display: "flex",
    alignContent: "center",
  },
  [`& .${classes.errorText}`]: {
    padding: "2px 0 0 5px",
    color: "red",
  },
}));
const FormFieldError = ({ text }) => {
  if (!text) return null;
  return React.createElement(
    Root,
    { className: classes.error },
    React.createElement(WarningIcon, { color: "error", fontSize: "small" }),
    React.createElement(
      "span",
      { "data-test-name": "input-error", className: classes.errorText },
      text,
    ),
  );
};
export default FormFieldError;
