import * as Yup from "yup";

export const initialValues = {
  email: "",
  password: "",
  passwordConfirmation: "",
  role: "",
  firstName: "",
  lastName: "",
  phone: "",
  address: "",
};

export const emailAndPasswordValidationSchema = Yup.object({
  email: Yup.string()
    .trim()
    .email("Invalid email address")
    .required("Required"),
  password: Yup.string().trim().required("Required"),
});
