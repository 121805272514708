import React from "react";

import { usePutOrderProcessMutation } from "components/Order/controller";
import { SuccessDialog } from "components/SuccessDialog";
import { useQuery } from "react-query";
import { useLocation, useParams } from "react-router-dom";
import { getOrder } from "service";

import {
  Card,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
} from "@mui/material";

import { useStyles } from "./OrderCreationDialog";
import {
  StyledButton3,
  StyledTypography18,
  StyledTypography5,
} from "./style.js";

export const VerifyPackageInfo = () => {
  const [showStep2, setShowStep2] = React.useState(false);
  const classes = useStyles();
  const putOrderProcessMutation = usePutOrderProcessMutation();
  const location = useLocation();
  const { selectedImage } = location.state;
  const { file } = location.state;
  const { orderId, encodedImage } = useParams();
  const imageName = atob(encodedImage || "");

  const order_query = useQuery(["order", orderId || ""], () =>
    getOrder(orderId || "")
  );
  const [order, setOrder] = React.useState({});
  React.useEffect(() => {
    console.log("Order Query:", order_query);
    const order_data = order_query.data?.order;
    setOrder(order_data);
    console.log("Order_data:", order_data);
  }, [order_query]);

  const handleVerifyPackageClick = async () => {
    if (!imageName) {
      return;
    }
    try {
      const processedCustomerName =
        order.customer.name.first + " " + order.customer.name.last;
      const processedFromCompany = order.fromCompany;
      const processedReferenceNo = order.referenceNo;
      const processedCourier = order.courier;
      const processedNote = order.note;
      const processedImg = selectedImage ? selectedImage : file;
      await putOrderProcessMutation.mutateAsync({
        orderId: orderId || "undefined",
        processedFromCompany: processedFromCompany,
        processedReferenceNo: processedReferenceNo,
        processedCourier: processedCourier,
        processedNote: processedNote,
        processedCustomerName: processedCustomerName,
        processedImg: processedImg,
      });
      console.log("processedCustomerName", processedCustomerName);
      setShowStep2(true);
    } catch (error) {}
  };

  const handleClose = (isLastStep = false) => {
    setShowStep2(false);
    if (isLastStep) {
      window.location.replace(
        `${window.location.origin}${process.env.PUBLIC_URL}`
      );
    }
  };

  return (
    <>
      <Grid container justifyContent="center" style={{ height: "100vh" }}>
        <Grid item xs={12} sm={8} md={6} lg={4} xl={4}>
          <DialogTitle
            className={classes.sendPackage}
            align="center"
            style={{ marginTop: "20px" }}
          >
            <span className="send">Verify </span>
            <span className="package">Information</span>
          </DialogTitle>

          <DialogContent
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              {imageName && (
                <img
                  src={imageName}
                  alt="Package"
                  style={{ maxWidth: "50%", height: "auto" }}
                />
              )}
              <Card
                variant="outlined"
                sx={{
                  width: "70%",
                  marginTop: "20px",
                  backgroundColor: "#F3E6D4",
                  borderRadius: "7px",
                  padding: "10px",
                  boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.2)",
                }}
              >
                <StyledTypography5>
                  Customer Name: {order?.customer?.name.first}{" "}
                  {order?.customer?.name.last}
                </StyledTypography5>
                <StyledTypography5>
                  From Company: {order?.fromCompany}
                </StyledTypography5>
                <StyledTypography5>
                  Reference No:{" "}
                  {order?.referenceNo?.length > 0 ? order?.referenceNo : "N/A"}
                </StyledTypography5>
                <StyledTypography5>
                  Courier: {order?.courier?.length > 0 ? order?.courier : "N/A"}
                </StyledTypography5>
                <StyledTypography5>
                  Note: {order?.note?.length > 0 ? order?.note : "N/A"}
                </StyledTypography5>
              </Card>
            </div>
          </DialogContent>
          <StyledTypography18 align="center">
            The photo of the package has been successfully taken
          </StyledTypography18>
          <StyledTypography18
            align="center"
            marginLeft="20px"
            marginRight="20px"
          >
            Please verify the information on the package matches with the
            Customer submitted information.
          </StyledTypography18>
          <DialogActions style={{ justifyContent: "center" }}>
            <StyledButton3
              variant="contained"
              onClick={handleVerifyPackageClick}
            >
              VERIFY PACKAGE & NOTIFY CUSTOMER
            </StyledButton3>
          </DialogActions>
        </Grid>
      </Grid>
      <SuccessDialog
        open={showStep2}
        handleClose={() => handleClose(true)}
        dialogTitle={"Success!"}
        dialogText={""}
        buttonText={"Return to main menu"}
        imgSrc={`${process.env.PUBLIC_URL}/VerifyPackageSuccess.png`}
      />
    </>
  );
};
