import React, { useEffect } from 'react';

import { clearLocalStorageAndReload } from '../api/provider';

const Logout: React.FC = () => {
  useEffect(() => {
    clearLocalStorageAndReload();
  }, []);

  return <div>Logging out...</div>;
};

export default Logout;
