import { dataProvider } from 'api';
import {
  IOrder,
  IOrderChat,
  IOrderGet,
  IOrderPatch,
  IOrderPickUp,
  IOrderPost,
  IOrderPutProcess,
  IOrderRate,
  UserRole,
} from 'interfaces';
import { isHolder } from 'utils';

import {
  chat_path,
  order_image_path,
  order_path,
  order_pickup_path,
  order_process_path,
  order_rate_path,
  orders_path,
  post_orders_path,
} from '../paths';

export const getOrder = async (orderId: string): Promise<IOrder> =>
  dataProvider.get(order_path(orderId)).json();

/*export const getOrders = async (): Promise<{ results: IOrderGet[] }> =>
  dataProvider.get(orders_path(getCurrentUser().role)).json();*/

export const getOrders = async (skip: number, limit: number): Promise<{ results: IOrderGet[] }> => {
  // const currentUserRole = getCurrentUser().role;
  const currentUserRole = isHolder() ? UserRole.HOLDER : UserRole.CUSTOMER;
  const headers = {
    'HT-role': currentUserRole,
  };

  const queryParams = new URLSearchParams({
    skip: skip.toString(),
    limit: limit.toString()
  });

  const url = `${orders_path(currentUserRole)}?${queryParams}`;

  return dataProvider.get(url, { headers }).json();
};

export const postOrder = async ({
  store,
  carrier,
  tracking_number,
  address,
  package_size,
  notes,
  expectedArrival
}: IOrderPost): Promise<Response> => {

  try {
    const body: any = {
      order: {
        fromCompany: store,
        courier: carrier,
        referenceNo: tracking_number,
        address,
        expectedArrival,
        weightKg: package_size,
        note: notes,
      }
    }
    return dataProvider.post(post_orders_path(), {
      json: body,
    });
  } catch (error) {
    console.log("@@@@@@@@@@@@@@@@ error ", error)
    throw error;
  }
}

export const patchOrder = async ({
  orderId,
  confirmation_code,
  tracking_number,
  notes
}: IOrderPatch): Promise<Response> => {
  const body: any = {
    order: {
      orderId,
      referenceNo: tracking_number,
      confirmation_code,
      note: notes,
    }
  }
  dataProvider.put(order_path(orderId), {
    json: body,
  });
}


export const putOrderPickUp = async ({
  pickupShortCode,
  status,
}: IOrderPickUp): Promise<any> => {
  const body: any = {
    order: {
      pickupShortCode,
      status,
    }
  }
  await dataProvider.put(order_pickup_path(pickupShortCode
), {
    json: body,
  }).json();
}

export const putOrderProcess = async ({
  orderId,
  processedFromCompany,
  processedReferenceNo,
  processedCourier,
  processedNote,
  processedCustomerName,
  processedImg,
}: IOrderPutProcess): Promise<Response> => {
  try {
    const imageFormData = new FormData();
    imageFormData.append('image', processedImg);
    const imageRes: any = await dataProvider.put(order_image_path(orderId), {
      body: imageFormData,
    }).json();

    if(!imageRes.filePath){
      throw Error('image is requred')
    }
    const body: any = {
      order: {
        orderId,
        processedCustomerName,
        processedFromCompany,
        processedReferenceNo,
        processedCourier,
        processedNote,
        processedImg: imageRes.filePath
      }
    };
    await dataProvider.put(order_process_path(orderId), {
      json: body,
    });

    return Promise.resolve();
  } catch (error) {
    return Promise.reject(error);
  }
};


export const getChatUserID = async (orderId: string): Promise<IOrderChat> => {
  // const currentUserRole = getCurrentUser().role;
  const currentUserRole = isHolder() ? UserRole.HOLDER : UserRole.CUSTOMER;
  const headers = {
    'HT-role': currentUserRole,
  };
  const url = `${chat_path(orderId)}`;
   return dataProvider.get(url, { headers }).json();
}


export const putOrderRate = async ({
  rating,
  ratingComment,
  tip,
  orderId,
}: IOrderRate): Promise<any> => {
  const body: any = {
    orderId,
    rating,
    ratingComment,
    tip,
  }
  await dataProvider.put(order_rate_path(orderId), {
    json: body,
  }).json();
}
