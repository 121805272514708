import { red } from "@mui/material/colors";
import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  palette: {
    type: "light",
    primary: {
      main: "#ff741e",
      light: "#FFC04D",
      dark: "#FF9858",
    },
    secondary: {
      main: "#FF9858",
      light: "#FFC04D",
      dark: "#FF9858",
    },

    error: {
      main: red.A400,
    },
    background: {
      default: "#ffffff",
    },
    text: {
      primary: "#474747",
    },
  },
  overrides: {
    MuiPaper: {
      root: {
        padding: "20px 10px",
        margin: "10px",
        backgroundColor: "#fff", // 5d737e
      },
    },
    MuiButton: {
      root: {
        margin: "5px",
      },
    },
  },
});

export default theme;
