import format from "date-fns/format";

export const dateFormatterMonthDay = (date: string) => {
  if (!date) return "";

  return format(new Date(date), "MMM dd");
};

export const dateFormatterMonthDayTime = (date: string) => {
  if (!date) return "";

  return format(new Date(date), "dd MMM HH:mm:ss");
};
