import React, { useState } from "react";

import { useParams } from "react-router-dom";

import {
  Box,
  Button,
  Container,
  Grid,
  Rating,
  TextField,
  Typography,
} from "@mui/material";

import { SuccessDialog } from "../SuccessDialog";
import { useRateAndTipMutation } from "./controller";

const RatingandTipping = () => {
  const [tipAmount, setTipAmount] = useState<number | string>(0);
  const [customTip, setCustomTip] = useState<string>("");
  const [rating, setRating] = useState<number>(0);
  const [ratingComment, setRatingComment] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [showSuccessDialog, setShowSuccessDialog] = useState<boolean>(false);
  const [isNoTipSelected, setIsNoTipSelected] = useState(false);
  const { orderId } = useParams();
  const putRateAndTipMutation = useRateAndTipMutation();

  const handleTip = (amount: number) => {
    setTipAmount(amount);
    setCustomTip("");
    setIsNoTipSelected(amount === 0);
  };

  const handleCustomTipChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setCustomTip(event.target.value);
    setTipAmount(0);
    setIsNoTipSelected(false);
  };

  const handleSubmit = async () => {
    const tip = customTip ? parseFloat(customTip) : tipAmount;

    if (rating === 0 || (tip === 0 && customTip === "")) {
      if (rating === 0 || (!isNoTipSelected && customTip === "")) {
        alert("Please provide both rating and tip amount");
        return;
      }
    }
    setIsLoading(true);

    try {
      await putRateAndTipMutation.mutateAsync({
        orderId,
        rating,
        ratingComment,
        tip,
      });
      console.log("Rating:", rating);
      setShowSuccessDialog(true);
    } catch (error) {
      console.error("Submission failed:", error);
      alert("Submission failed. Please try again later.");
    } finally {
      setIsLoading(false);
    }
  };

  const handleClose = () => {
    setShowSuccessDialog(false);
    window.location.replace(
      `${window.location.origin}${process.env.PUBLIC_URL}`
    );
  };

  return (
    <div>
      <Container maxWidth="sm">
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            mt: 8,
            p: 3,
            boxShadow: 3,
            borderRadius: 2,
            backgroundColor: "background.paper",
          }}
        >
          <Typography variant="h5" component="h1" gutterBottom>
            Order Complete
          </Typography>
          <Typography variant="body1" gutterBottom>
            Package successfully handed off!
          </Typography>
          {/*<Avatar
            alt="Holder"
            src="https://via.placeholder.com/150" // Replace with the actual image URL
            sx={{ width: 56, height: 56, mt: 2, mb: 2 }}
        />*/}
          <Typography variant="body2" gutterBottom>
            Rate your experience with this Holder:
          </Typography>
          <Rating
            name="holder-rating"
            value={rating}
            onChange={(event, newValue) => setRating(newValue as number)}
            sx={{ color: "#ff741e" }}
          />
          <Typography variant="body2" gutterBottom mt={2}>
            Say thanks with a tip:
          </Typography>
          <Grid container spacing={2} justifyContent="center" mt={1}>
            {[1, 2, 5].map((amount) => (
              <Grid item key={amount}>
                <Button
                  variant={tipAmount === amount ? "contained" : "outlined"}
                  color="primary"
                  onClick={() => handleTip(amount)}
                  sx={{
                    borderColor: "#ff741e",
                    color: tipAmount === amount ? "#fff" : "#ff741e",
                  }}
                >
                  ${amount}
                </Button>
              </Grid>
            ))}
            <Grid item>
              <Button
                variant={isNoTipSelected ? "contained" : "outlined"}
                color="primary"
                onClick={() => handleTip(0)}
                sx={{
                  borderColor: "#ff741e",
                  color: isNoTipSelected ? "#fff" : "#ff741e",
                }}
              >
                No Tip
              </Button>
            </Grid>
          </Grid>
          <Box mt={2}>
            <Typography variant="body2" gutterBottom>
              or enter a custom amount:
            </Typography>
            <TextField
              type="number"
              label="Custom Tip"
              value={customTip}
              onChange={handleCustomTipChange}
              variant="outlined"
              InputProps={{
                startAdornment: <Typography variant="body1">$</Typography>,
              }}
              fullWidth
              sx={{ mt: 1 }}
            />
          </Box>
          <Box mt={2}>
            <Typography variant="body2" gutterBottom>
              Additional comments:
            </Typography>
            <TextField
              label="Comment"
              value={ratingComment}
              onChange={(event) => setRatingComment(event.target.value)}
              variant="outlined"
              fullWidth
              multiline
              rows={3}
              sx={{ mt: 1 }}
            />
          </Box>
          <Box mt={3} width="100%">
            <Button
              variant="contained"
              color="primary"
              onClick={handleSubmit}
              fullWidth
              sx={{ backgroundColor: "#ff741e", color: "#fff" }}
              disabled={isLoading}
            >
              {isLoading ? "Submitting..." : "Submit"}
            </Button>
          </Box>
        </Box>
      </Container>
      <SuccessDialog
        open={showSuccessDialog}
        handleClose={handleClose}
        dialogTitle="Success!"
        dialogText="Your tips and ratings go a long way towards supporting the Holders that provide security and trust in our neighborhoods."
        buttonText="Return to main menu"
        imgSrc={`${process.env.PUBLIC_URL}/pick_up_success.png`}
      />
    </div>
  );
};

export default RatingandTipping;
