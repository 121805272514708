import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import React from "react";
import { makeStyles } from "@mui/styles";
import { useStyles } from "../Order/OrderCreationDialog";
import { StyledButton3 } from "../Order/style.js";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

export const SuccessDialog: React.FC<{
  open: boolean;
  handleClose: any;
  dialogTitle: string;
  dialogText: string;
  imgSrc: string;
  buttonText: string;
}> = ({ open, handleClose, dialogTitle, dialogText, imgSrc, buttonText }) => {
  const classes = useStyles();

  return (
    <Dialog
      fullWidth
      open={open}
      onClose={handleClose}
      PaperProps={{
        style: {
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
          justifyContent: "center",
          width: "450px",
          height: "auto",
          padding: "20px",
          borderRadius: "10px",
        },
      }}
    >
      <div
        style={{
          alignSelf: "flex-end",
          position: "absolute",
          top: "10px",
          right: "10px",
        }}
      >
        <IconButton onClick={handleClose}>
          <CloseIcon />
        </IconButton>
      </div>
      <DialogTitle className={classes.sendPackage} align="center">
        {dialogTitle.split(" ").map((word, index) => (
          <span
            key={index}
            className={index === 0 ? "send" : index === 1 ? "package" : ""}
          >
            {word} {index !== dialogTitle.split(" ").length - 1 ? " " : ""}
          </span>
        ))}
      </DialogTitle>
      <DialogContent>
        <Typography
          align="center"
          sx={{
            fontSize: "0.9rem",
            marginTop: "5px",
            lineHeight: "1.18",
            marginBottom: "30px",
            "@media (max-width: 768px)": {
              fontSize: "0.76rem",
            },
            "@media (max-width: 480px)": {
              fontSize: "0.68rem",
            },
          }}
          variant="body2"
        >
          {dialogText}
        </Typography>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <img
            src={imgSrc}
            style={{
              width: "60%",
              height: "60%",
              marginRight: "8px",
              marginBottom: "18px",
              ...(imgSrc === "/order_completed_holder.png"
                ? { width: "43%", height: "43%" }
                : {}),
            }}
          />
        </div>
      </DialogContent>
      <DialogActions>
        <StyledButton3
          onClick={handleClose}
          variant="contained"
          sx={{
            color: "#ffffff",
            fontWeight: "550",
            marginBottom: "25px",
            marginLeft: "1.2rem",
          }}
        >
          {buttonText}
        </StyledButton3>
      </DialogActions>
    </Dialog>
  );
};
