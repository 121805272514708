import React, {
  useMemo,
  useState,
} from 'react';

import { useQuery } from 'react-query';
import {
  useNavigate,
  useParams,
} from 'react-router-dom';
import { getOrders } from 'service';

import {
  DialogTitle,
  Grid,
  TextField,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import Button from '@mui/material/Button';

import { useStyles } from './OrderCreationDialog';
import { StyledTypography18 } from './style.js';

const OrderCompletionDialog = () => {
  //const [confirmationCode, setConfirmationCode] = React.useState(null);
  const [confirmationCode, setConfirmationCode] = React.useState("");

  const { orderId } = useParams();
  const [skip, setSkip] = useState(0);
  const limit = 99999999999999999999;
  const classes = useStyles();
  const theme = useTheme();
  const navigate = useNavigate();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const isMediumScreen = useMediaQuery(theme.breakpoints.down("md"));

  /*const orders_query = useQuery(["order"], getOrders.bind(this));

  useEffect(() => {
    console.log("Orders query data:", orders_query.data);
  }, [orders_query.data]);

  const orders = useMemo(() => {
    const data = orders_query.data?.orders || [];
    return data;
  }, [orders_query.data?.orders]);*/

  const orders_query = useQuery(["order", skip], () => getOrders(skip, limit), {
    keepPreviousData: true,
  });

  const orders = useMemo(() => {
    const existingOrders = new Set(orders_query.data?.orders || []);
    return [...existingOrders];
  }, [orders_query.data?.orders]);

  const handleEnterCode = async () => {
    if (orders === undefined || orders === null) {
      return;
    }

    const order = orders.find(
      (order) => order.pickupShortCode === confirmationCode
    );

    if (order !== undefined) {
      navigate(
        `/retrieve-order/${orderId}?confirmationCode=${confirmationCode}`
      );
    } else {
      alert("Order not found. Please check the confirmation code.");
    }
  };

  return (
    <Grid container justifyContent="center" style={{ height: "100vh" }}>
      <Grid item xs={12} sm={8} md={6} lg={4} xl={4}>
        <DialogTitle
          className={classes.sendPackage}
          align="center"
          sx={{ mt: 5 }}
        >
          <span className="send">Enter </span>
          <span className="package">Confirmation Code</span>
        </DialogTitle>
        <StyledTypography18
          align="center"
          sx={{
            paddingTop: isSmallScreen ? 1 : isMediumScreen ? 1 : 1.5,
            paddingLeft: isSmallScreen ? 5 : isMediumScreen ? 10 : 6,
            paddingRight: isSmallScreen ? 5 : isMediumScreen ? 10 : 6,
          }}
        >
          Please request confirmation code from customer before retrieving their
          order. If customer does not have confirmation code, please have them
          contact Customer Support
        </StyledTypography18>
        <Grid container justifyContent="center">
          <TextField
            size="small"
            fullWidth
            id="name"
            type="number"
            InputProps={{
              inputProps: {
                max: 99999,
                min: 10000,
              },
            }}
            sx={{
              boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
              bgcolor: "#F1F1F1",
              mb: 3.2,
              mt: 3,
              width: isSmallScreen ? "60%" : isMediumScreen ? "65%" : "52%",
            }}
            value={confirmationCode}
            onChange={(event) => setConfirmationCode(event.target.value)}
          />

          <Button
            variant="contained"
            color="primary"
            fullWidth
            sx={{
              width: isSmallScreen ? "60%" : isMediumScreen ? "65%" : "52%",
              color: "#ffffff",
              fontWeight: "550",
              padding: 1,
              fontSize: isSmallScreen ? "10px" : "14px",
              mb: 7,
            }}
            onClick={handleEnterCode}
          >
            Verify CONFIRMATION CODE
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default OrderCompletionDialog;
