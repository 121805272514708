import React from "react";
import SbChannelList from "@sendbird/uikit-react/GroupChannelList";

import { useNavigate } from "react-router-dom";
import SbChannel from "@sendbird/uikit-react/GroupChannel";

export function MobileChannelList({channelUrl}: {channelUrl?: string}) {

  const navigate = useNavigate();

  return (
    <div style={{ height: "90vh" }}>
      {!channelUrl ? (
        <SbChannelList
          selectedChannelUrl={channelUrl}
          allowProfileEdit={false}
          disableAutoSelect
          onChannelCreated={(channel) => {}}
          onChannelSelect={(channel) => {
            if (channel) {
              const orderData = JSON.parse(channel.data);
              navigate(`/messages/${channel.url}?orderId=${orderData.orderId}`);
            }
          }}
        />
      ) : (
        <SbChannel
          channelUrl={channelUrl as string}
          onBackClick={() => {
            navigate("/messages");
          }}
        />
      )}
    </div>
  );
}
