import * as React from 'react';

import { usePutOrderPickUpMutation } from 'components/Order/controller';
import { OrderStatus } from 'interfaces';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { getOrder } from 'service';

import {
  Card,
  CardMedia,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  useMediaQuery,
  useTheme,
} from '@mui/material';

import { SuccessDialog } from '../SuccessDialog';
import { useStyles } from './OrderCreationDialog';
import {
  StyledButton3,
  StyledTypography18,
  StyledTypography5,
} from './style.js';

const RetrieveOrderDialog = () => {
  const classes = useStyles();
  const handleClose = (isLastStep = false) => {
    //setShowStep1(false);
    setShowStep2(false);
    // setShowRetrieveOrderDialog(false);
    if (isLastStep) {
      window.location.replace(
        `${window.location.origin}${process.env.PUBLIC_URL}`
      );
    }
  };
  const { orderId } = useParams();

  const order_query = useQuery(["order", orderId || ""], () =>
    getOrder(orderId || "")
  );
  const [order, setOrder] = React.useState({});
  React.useEffect(() => {
    console.log("Order Query:", order_query);
    const order_data = order_query.data?.order;
    console.log("RetrieveOrder:", order_data);
    setOrder(order_data);
  }, [order_query]);

  //const [showStep1, setShowStep1] = React.useState(showRetrieveOrderDialog);
  const [showStep2, setShowStep2] = React.useState(false);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const isMediumScreen = useMediaQuery(theme.breakpoints.down("md"));
  const { handleOrderPickUp } = usePutOrderPickUpMutation();
  const confirmationCode = order?.pickupShortCode;

  const handleConfirm = async () => {
    try {
      console.log("Status:", OrderStatus.DELIVERED);
      console.log(
        "Pickup Short Code:",
        confirmationCode ? confirmationCode.toString() : ""
      );

      handleOrderPickUp(
        {
          status: OrderStatus.DELIVERED,
          pickupShortCode: confirmationCode ? confirmationCode.toString() : "",
        },
        () => {
          // setShowStep1(false);
          setShowStep2(true);
        }
      ).catch((error) => {
        console.error("Error occurred:", error);
      });
    } catch (error) {
      console.error("Error occurred:", error);
    }
  };

  return (
    <>
      <Grid container justifyContent="center" style={{ height: "100vh" }}>
        <Grid item xs={12} sm={8} md={6} lg={4} xl={4}>
          <DialogTitle
            className={classes.sendPackage}
            align="center"
            style={{ marginTop: "20px" }}
          >
            <span className="send">Retrieve </span>
            <span className="package">Package</span>
          </DialogTitle>
          <StyledTypography18
            align="center"
            sx={{
              paddingTop: isSmallScreen ? 1 : isMediumScreen ? 1 : 3,
              paddingLeft: isSmallScreen ? 5 : isMediumScreen ? 10 : 10,
              paddingRight: isSmallScreen ? 5 : isMediumScreen ? 10 : 10,
            }}
          >
            Your Order Confirmation Code has been verified, please retrieve and
            handoff the following package
          </StyledTypography18>
          <DialogContent
            sx={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Card
              variant="outlined"
              sx={{
                width: "70%",
                paddingTop: "25px",
                paddingBottom: "25px",
                paddingRight: "25px",
                backgroundColor: "#F3E6D4",
                borderRadius: "7px",
                boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.2)",
                justifyContent: "center",
                alignContent: "center",
              }}
            >
              <CardMedia
                component="img"
                src={order?.processedImg?.full}
                alt="Full Image"
                sx={{
                  width: "70%",
                  marginBottom: "5%",
                  marginLeft: "20%",
                }}
              />

              <StyledTypography5>
                Name:{" "}
                {order && order.customer
                  ? `${order.customer.name.first} ${order.customer.name.last}`
                  : "N/A"}
              </StyledTypography5>
              <StyledTypography5 sx={{ marginTop: "12px" }}>
                Company: {order ? order.fromCompany : "N/A"}
              </StyledTypography5>
              <StyledTypography5 sx={{ marginTop: "12px" }}>
                Shipping / Courier: {order ? order.courier : "N/A"}
              </StyledTypography5>
              <StyledTypography5 sx={{ marginTop: "12px" }}>
                Tracking number: {order ? "#" + order.referenceNo : "N/A"}
              </StyledTypography5>
              <StyledTypography5 sx={{ marginTop: "12px" }}>
                Customer Notes: {order ? order.note : "N/A"}
              </StyledTypography5>
            </Card>
          </DialogContent>
          <DialogActions style={{ justifyContent: "center" }}>
            <StyledButton3
              variant="contained"
              sx={{
                marginBottom: "45px",
                marginLeft: "1.2rem",
              }}
              onClick={handleConfirm}
            >
              Confirm Handoff
            </StyledButton3>
          </DialogActions>
        </Grid>
      </Grid>
      <SuccessDialog
        open={showStep2}
        handleClose={() => handleClose(true)}
        dialogTitle={"Success!"}
        dialogText={
          "Order complete! Thank you for helping your neighbours to secure their packages."
        }
        buttonText={"Return to main menu"}
        imgSrc={`${process.env.PUBLIC_URL}/order_completed_holder.png`}
      />
    </>
  );
};

export default RetrieveOrderDialog;
