import React from 'react';

import {
  IOrder,
  OrderStatus,
} from 'interfaces';
import {
  dateFormatterMonthDay,
  isCustomer,
  isHolder,
} from 'utils';

import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';

export const statusUI = {
  [OrderStatus.AWAITING_DELIVERY]: "Awaiting Delivery",
  [OrderStatus.READY_FOR_PICKUP]: "Ready for Pickup",
  [OrderStatus.DELIVERED]: "Delivered",
  [OrderStatus.CANCELLED]: "Cancelled",
  [OrderStatus.INDELIVERY]: "In Delivery",
  [OrderStatus.LOST]: "Lost",
  [OrderStatus.PAYMENTMETHOD_REQUIRED]: "Payment Method Required",
  [OrderStatus.PAID_OUT]: "Delivered",
};

const OrderCard: React.FC<{
  order: IOrder;
  backgroundColor: string;
  cardImage: string;
  isSmallScreen: boolean;
}> = ({ order, backgroundColor, cardImage, isSmallScreen }) => {
  const handleClick = () => {
    console.log("Redirecting to URL:", `/orders/${order._id}`);
    window.location.href = `${process.env.PUBLIC_URL}/orders/${order._id}`;
  };
  console.log("OrderCardImage:", cardImage);

  return (
    <Card
      sx={{
        borderRadius: "15px",
        paddingLeft: "32px",
        paddingRight: "20px",
        margin: "0 auto",
        marginTop: "20px",
        backgroundColor: backgroundColor,
        display: "flex",
        "@media (max-width: 480px)": {
          paddingLeft: "15px",
          paddingRight: "10px",
        },
      }}
    >
      <div style={{ flex: 1 }}>
        <CardContent>
          <Typography
            variant="h6"
            sx={{
              "@media (max-width: 480px)": {
                fontSize: "1rem",
                fontWeight: "550",
              },
            }}
            gutterBottom
          >
            {order.fromCompany + ", " + dateFormatterMonthDay(order.createdAt)}
          </Typography>
          <Typography variant="body2">
            {"Ordered on: " + dateFormatterMonthDay(order.createdAt)}
          </Typography>
          {order.status !== OrderStatus.READY_FOR_PICKUP ? (
            <Typography variant="body2">
              {"Status: "}
              <strong>{statusUI[order.status]}</strong>
            </Typography>
          ) : (
            <>
              {isCustomer() && (
                <Typography variant="body2">
                  {"Status: "}
                  <strong style={{ color: "#ff741e" }}>
                    {statusUI[order.status]}
                  </strong>
                </Typography>
              )}
              {isHolder() && (
                <Typography variant="body2">
                  {"Status: "}
                  <strong style={{ color: "#ff741e" }}>
                    Customer Arriving
                  </strong>
                </Typography>
              )}
            </>
          )}
          <Typography variant="body2">
            {"Shipped via: " + order.courier}
          </Typography>
          <Typography variant="body2">
            {"Tracking #: " + order.referenceNo}
          </Typography>
          {/* {isCustomer() && (
            <Typography variant="body2">{"Store: " + order.store}</Typography>
          )}
          {isCustomer() && (
            <Typography variant="body2">
              {"Holder info: " +
                order.holder.phone +
                ", " +
                order.holder.first_name +
                " " +
                order.holder.last_name}
            </Typography>
          )}*/}
        </CardContent>
        <CardActions>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              marginTop: "-20px",
              marginLeft: "3px",
            }}
          >
            {isCustomer() && order.status === OrderStatus.READY_FOR_PICKUP && (
              <Button
                variant="contained"
                onClick={handleClick}
                size="small"
                sx={{
                  color: "#ffffff",
                  marginBottom: "10px",
                  marginTop: "10px",
                  fontWeight: "550",
                }}
              >
                Get Directions
              </Button>
            )}
            {isCustomer() && order.status !== OrderStatus.READY_FOR_PICKUP && (
              <Button
                size="small"
                onClick={handleClick}
                style={{ fontWeight: "500" }}
              >
                details
              </Button>
            )}
            {isHolder() &&
              (order.status === OrderStatus.AWAITING_DELIVERY ||
                order.status === OrderStatus.INDELIVERY ||
                order.status === OrderStatus.PAYMENTMETHOD_REQUIRED) && (
                <Button
                  variant="contained"
                  onClick={handleClick}
                  size="small"
                  sx={{
                    color: "#ffffff",
                    marginBottom: "10px",
                    marginTop: "10px",
                    fontWeight: "550",
                  }}
                >
                  PACKAGE HAS ARRIVED
                </Button>
              )}
            {isHolder() &&
              order.status !== OrderStatus.AWAITING_DELIVERY &&
              order.status !== OrderStatus.INDELIVERY &&
              order.status !== OrderStatus.PAYMENTMETHOD_REQUIRED && (
                <>
                  <Button size="small" onClick={handleClick}>
                    Details
                  </Button>
                </>
              )}
          </div>
        </CardActions>
      </div>
      <div
        style={{
          width: "150px",
          height: "150px",
          position: "relative",
          overflow: "hidden",
        }}
      >
        <img
          src={cardImage}
          alt="Image"
          style={{
            width: "100%",
            height: "auto",
            maxHeight: "100%",
            maxWidth: "100%",
            objectFit: "cover",
            marginTop: "15%",
          }}
        />
      </div>
    </Card>
  );
};

export default OrderCard;
