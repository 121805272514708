
export const login_path = () => `users/signin/ht`;

export const signup_path = () => `users/signup/ht`;

export const user_me_path = () => "users/me";

export const email_confirm_send_path = () => "users/verify/email/";

export const email_confirm_verify_path = () => "users/verify";

export const connect_account = () => `users/me/connect`;

export const chat_access_token = () => `users/twilioAccessToken`;

export const forgot_password_path = () => "users/resetpassword";

export const forgot_password_email_send_path = (email: string) => `users/resetpassword?email=${email}`;

export const change_password_path = () => "users";
