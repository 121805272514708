import { config } from "config";
import dataProvider from "../provider";

const REFRESH_TOKEN = "users/token/auth/refresh/";

const createApi = () => {
  const provider = dataProvider(`${config.api}`);

  const refreshTokens = async (token: any) => {
    return await provider
      .post(REFRESH_TOKEN, { json: { refresh: token } })
      .json();
  };

  return {
    refreshTokens,
  };
};

export default createApi;
