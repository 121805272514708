import { useUser } from 'hooks/useUser';
import { useSnackbar } from 'notistack';
import { useMutation } from 'react-query';
import {
  connectAccount,
  getEmailConfirmSend,
  postEmailConfirm,
  postSignin,
  postSignUp,
} from 'service';

export const ERROR_NOT_ACTIVATED = 'Your account was not activated';

export interface LoginPayload {
  email?: string;
  password?: string;
}
export interface ControllerInterface {
  handleLogin: (params: LoginPayload) => Promise<any>;
}


export const useLogin = (): ControllerInterface => {
  const { enqueueSnackbar } = useSnackbar();
  const { refetch } = useUser();

  const postLoginData = useMutation<any, any, LoginPayload>(
    postSignin,
    {
      onSuccess: (data: any) => {
        return refetch(); // get user
      },
      onError: (error: any) => {
        const errorText = error?.msg || 'Unknown error when sign in, please try again or contact support if you still get this';
        if (errorText && !errorText.includes(ERROR_NOT_ACTIVATED)) {
          // any error except not activated will show error in the snackbar
          enqueueSnackbar(errorText, {
            variant: "error",
          });
          throw errorText;
        }
      }
    },
  );


  
   const handleLogin = async (
    params: any
  ): Promise<any> => {
    try {
      const data = await postLoginData.mutateAsync(params);
      const user = data.user ? data.user : null;
      const isHolder =  (user && Array.isArray(user.roles) && user.roles.includes('holder'));
      const userPayout = (user && user.payout) ? user.payout : null;
      const hasAccount = (userPayout && userPayout.account);
      const verifyAccount = (hasAccount && userPayout && userPayout.status === 'completed');
      if(isHolder && (!hasAccount || !verifyAccount)){
        const url = await connectAccount();
        return url;
      }
      return false;
    } catch (error) {      
      if (error && error?.msg && error?.msg.includes(ERROR_NOT_ACTIVATED)) {  
        return true;
      } else {
        throw error;
      }
    }
   };
  
  return {
    handleLogin,
  };
};


export interface ControllerInterface {
  handleSignUp: (params: any) => Promise<any>;
}

export const useSignUp = (): any => {
  const { enqueueSnackbar } = useSnackbar();

  const postSignUpData = useMutation<any, any, any>(postSignUp, {
    onSuccess: (data: any) => Promise.resolve(),
    onError: (error: any) => {
      const errorText = error?.msg || 'Unable to sign up at this time, please try again';
      enqueueSnackbar(errorText, {
        variant: 'error',
      });
      throw errorText;
    },
  });

  const handleSignUp = async (params: any): Promise<any> => {
    try {
      const data = await postSignUpData.mutateAsync(params);
      return getEmailConfirmSend(params.user);
    } catch (error) {
      throw error;
    }
  };

  return {
    handleSignUp,
  };
};

export const useConfirmation = (): any => {
  const { enqueueSnackbar } = useSnackbar();
  const putEmailConfirmData = useMutation<any, any, any>(postEmailConfirm, {
    onSuccess: (data: any) => Promise.resolve(),
    onError: (error: any) => {
      const errorText =
        error?.msg || "Unable to sign up at this time, please try again";
      enqueueSnackbar(errorText, {
        variant: "error",
      });
      throw errorText;
    },
  });

  const handleEmailConfirmation = async (
    params: any,
    onSuccess: () => void
  ): Promise<any> => {
    try {
      const data = await putEmailConfirmData.mutateAsync(params);
      onSuccess(); 
      return Promise.resolve();
    } catch (error) {
      throw error;
    }
  };

  return {
    handleEmailConfirmation,
  };
};