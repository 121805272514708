import * as React from 'react';
import { useRef } from 'react';

import { usePutOrderProcessMutation } from 'components/Order/controller';
import { useSnackbar } from 'notistack';
import { useQuery } from 'react-query';
import {
  useNavigate,
  useParams,
} from 'react-router-dom';
import { getOrder } from 'service';

import CloseIcon from '@mui/icons-material/Close';
import {
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Radio,
  RadioGroup,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import IconButton from '@mui/material/IconButton';

import { useStyles } from './OrderCreationDialog';
import {
  StyledButton3,
  StyledButton4,
  StyledTypography18,
} from './style.js';

type CameraType = "user" | "environment";

export const VerifyPackageDialog = () => {
  const { enqueueSnackbar } = useSnackbar();

  const classes = useStyles();
  const putOrderProcessMutation = usePutOrderProcessMutation();
  const theme = useTheme();
  const navigate = useNavigate();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const isMediumScreen = useMediaQuery(theme.breakpoints.down("md"));
  //const [showStep1, setShowStep1] = React.useState(showVerifyPackageDialog);
  const [packageImage, setPackageImage] = React.useState<File | null>(null);
  const [imagePreview, setImagePreview] = React.useState<string | null>(null);
  const videoRef = useRef<HTMLVideoElement>(null);
  const [isCameraStarted, setIsCameraStarted] = React.useState(false);
  const [camera, setCamera] = React.useState<CameraType>("environment"); // "user" for front camera, "environment" for back camera
  const [selectedMethod, setSelectedMethod] = React.useState<"file" | "camera">(
    "file"
  );

  const handleMethodChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedMethod(event.target.value as "file" | "camera");
  };

  const { orderId } = useParams();

  const order_query = useQuery(["order", orderId || ""], () =>
    getOrder(orderId || "")
  );
  const [order, setOrder] = React.useState({});
  React.useEffect(() => {
    console.log("Order Query:", order_query);
    const order_data = order_query.data?.order;
    setOrder(order_data);
  }, [order_query]);

  const handleSelectPhoto = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files && files.length > 0) {
      const selectedImage = files[0];
      setPackageImage(selectedImage);
      const reader = new FileReader();
      reader.onloadend = () => {
        if (typeof reader.result === "string") {
          const imagePreview = reader.result;

          setImagePreview(imagePreview);
          console.log("selectedImage:", selectedImage);
          const encodedImage = btoa(imagePreview);
          navigate(`/verify-package-info/${orderId}/${encodedImage}`, {
            state: { selectedImage },
          });
        } else {
          console.error("Failed to read the image file.");
        }
      };
      reader.readAsDataURL(selectedImage);
    }
  };

  const handleClose = (isLastStep = false) => {
    stopCamera();
    setPackageImage(null);
    setImagePreview(null);
    if (isLastStep) {
      window.location.replace(
        `${window.location.origin}${process.env.PUBLIC_URL}`
      );
    }
  };

  //Get Available Video Inputs
  async function getVideoInputs(): Promise<MediaDeviceInfo[]> {
    try {
      const devices = await navigator.mediaDevices.enumerateDevices();
      return devices.filter((device) => device.kind === "videoinput");
    } catch (error) {
      throw error;
    }
  }

  const stopCamera = () => {
    if (videoRef.current && videoRef.current.srcObject) {
      const stream = videoRef.current.srcObject as MediaStream;
      const tracks = stream.getVideoTracks();
      tracks.forEach((track) => {
        stream.removeTrack(track);
        track.stop();
      });
      videoRef.current.srcObject = null;
      setIsCameraStarted(false);
    }
  };

  const startCamera = async () => {
    try {
      await switchCamera(camera);
      // setIsCameraStarted(true);
    } catch (error) {
      console.error("Error accessing camera:", error);
    }
  };

  async function switchCamera(cameraType: CameraType) {
    try {
      console.error("Switching camera to:", cameraType);
      const stream = await loadStream(cameraType);
      // Assuming you have a <video> element in your HTML to display the video stream
      if (videoRef.current) {
        videoRef.current.srcObject = stream;
        console.log("Video stream started:", stream);
      }
      setIsCameraStarted(true);
      console.log("Camera started");
    } catch (error: any) {
      console.error("Error accessing the camera", error);
      enqueueSnackbar(
        error && error.message ? error.message : error.toString(),
        { variant: "error" }
      );
    }
  }

  const loadStream = async (cameraType: CameraType): Promise<MediaStream> => {
    try {
      let desiredCamera = await getDesiredCamera(cameraType);
      const supports = navigator.mediaDevices.getSupportedConstraints();
      console.log("Supported constraints:", supports);
      if (!desiredCamera || !desiredCamera.deviceId) {
        await navigator.mediaDevices.getUserMedia({ video: true });
        desiredCamera = await getDesiredCamera(cameraType);
      }

      if (!desiredCamera || !desiredCamera.deviceId) {
        throw new Error("Desired camera not found");
      }

      let constraints: MediaStreamConstraints = {
        video: { deviceId: { exact: desiredCamera.deviceId } },
      };

      // it does not work on my android device
      // if (supports["facingMode"]) {
      //   constraints = {
      //     video: { deviceId: { exact: desiredCamera.deviceId } },
      //     facingMode: {
      //       exact: desiredCamera.label.toLowerCase().includes("front")
      //         ? "user"
      //         : "environment",
      //     },
      //   } as MediaStreamConstraints;
      // }
      return await navigator.mediaDevices.getUserMedia(constraints);
    } catch (error) {
      console.error("Error loading stream:", error);
      throw error;
    }
  };

  const getDesiredCamera = async (
    cameraType: CameraType
  ): Promise<MediaDeviceInfo> => {
    try {
      const videoInputs = await getVideoInputs();

      if (!videoInputs.length) {
        throw new Error("Desired camera not found");
      }

      let desiredCamera = videoInputs[0]; // Default to the first camera if specific type not found

      if (cameraType === "user") {
        // Attempt to find the front camera
        desiredCamera =
          videoInputs.find((device) =>
            device.label.toLowerCase().includes("front")
          ) || videoInputs[0];
      } else if (cameraType === "environment") {
        // Attempt to find the back camera
        desiredCamera =
          videoInputs.find((device) =>
            device.label.toLowerCase().includes("back")
          ) || videoInputs[0];
      }

      return desiredCamera;
    } catch (error) {
      throw error;
    }
  };

  const updateCamera = () => {
    stopCamera();

    console.log("updateCamera:", camera);
    const newCameraType = camera === "user" ? "environment" : "user";

    setCamera(newCameraType);
    try {
      switchCamera(newCameraType);
      // setIsCameraStarted(true);
    } catch (error) {
      console.error("Error accessing camera:", error);
    }
  };

  const takePhoto = () => {
    if (videoRef.current) {
      const video = videoRef.current;
      const canvas = document.createElement("canvas");
      canvas.width = video.videoWidth;
      canvas.height = video.videoHeight;
      const context = canvas.getContext("2d");
      if (context) {
        context.drawImage(video, 0, 0, canvas.width, canvas.height);
        video.pause();
        video.srcObject = null;
        const imageDataUrl = canvas.toDataURL("image/png");
        setImagePreview(imageDataUrl);
        const extension = imageDataUrl.substring(
          "data:image/".length,
          imageDataUrl.indexOf(";")
        );
        const mimeType = imageDataUrl.split(";")[0].split(":")[1];
        const blobItem = dataURItoBlob(imageDataUrl);
        const file = new File([blobItem], `package_photo.${extension}`, {
          type: mimeType,
        });
        setPackageImage(file);
        stopCamera();
        const encodedImage = btoa(imageDataUrl);
        navigate(`/verify-package-info/${orderId}/${encodedImage}`, {
          state: { file },
        });
      }
    }
  };

  const dataURItoBlob = (dataURI: string) => {
    const byteString = atob(dataURI.split(",")[1]);
    const mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    const blob = new Blob([ia], { type: mimeString });
    return blob;
  };

  React.useEffect(() => {
    console.log("Orderer data:", order);
  }, [order]);
  console.log("image", imagePreview);
  return (
    <>
      <div
        style={{
          alignSelf: "flex-end",
          position: "absolute",
          top: "10px",
          right: "10px",
        }}
      >
        <IconButton onClick={() => handleClose(false)}>
          <CloseIcon />
        </IconButton>
      </div>
      <DialogTitle
        className={classes.sendPackage}
        align="center"
        style={{ marginTop: "20px" }}
      >
        <span className="send">Take </span>
        <span className="package">Package Photo</span>
      </DialogTitle>
      <StyledTypography18
        align="center"
        sx={{
          paddingTop: isSmallScreen ? 1 : isMediumScreen ? 1 : 1.5,
          paddingLeft: isSmallScreen ? 5 : isMediumScreen ? 10 : 10,
          paddingRight: isSmallScreen ? 5 : isMediumScreen ? 10 : 10,
        }}
      >
        Please take a photo of the package to verify the information
      </StyledTypography18>
      <DialogContent
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <div style={{ padding: "20px" }}>
          <RadioGroup
            row
            aria-label="photoMethod"
            name="photoMethod"
            value={selectedMethod}
            onChange={handleMethodChange}
          >
            <FormControlLabel
              value="file"
              control={<Radio />}
              label="Select from file"
            />
            <FormControlLabel
              value="camera"
              control={<Radio />}
              label="Take a photo"
            />
          </RadioGroup>
          {selectedMethod === "file" ? (
            <>
              <input
                accept="image/*"
                style={{ display: "none" }}
                id="package-image-upload"
                type="file"
                onChange={handleSelectPhoto}
              />
              <label htmlFor="package-image-upload">
                <StyledButton3
                  variant="contained"
                  component="span"
                  sx={{
                    marginBottom: "20px",
                    width: isSmallScreen ? "90%" : "auto",
                  }}
                >
                  Select Photo
                </StyledButton3>
              </label>
            </>
          ) : (
            <div>
              <video ref={videoRef} autoPlay playsInline width="100%"></video>

              {imagePreview && (
                <img
                  src={imagePreview}
                  alt="Preview"
                  style={{ maxWidth: "100%", height: "auto" }}
                />
              )}
              {selectedMethod === "camera" && !isCameraStarted && (
                <StyledButton3
                  variant="contained"
                  sx={{
                    marginBottom: "20px",
                    width: isSmallScreen ? "90%" : "auto",
                  }}
                  onClick={startCamera}
                >
                  Start Camera
                </StyledButton3>
              )}
              {selectedMethod === "camera" && isCameraStarted && (
                <>
                  <StyledButton3
                    variant="contained"
                    sx={{
                      marginBottom: "20px",
                      width: isSmallScreen ? "90%" : "auto",
                    }}
                    onClick={takePhoto}
                  >
                    Take Photo
                  </StyledButton3>
                  <StyledButton3
                    variant="contained"
                    sx={{
                      marginBottom: "20px",
                      width: isSmallScreen ? "90%" : "auto",
                    }}
                    onClick={updateCamera}
                  >
                    Switch Camera
                  </StyledButton3>

                  <StyledButton4
                    variant="contained"
                    sx={{
                      marginBottom: "20px",
                      width: isSmallScreen ? "90%" : "auto",
                    }}
                    onClick={stopCamera}
                  >
                    Stop Camera
                  </StyledButton4>
                </>
              )}
            </div>
          )}
        </div>
      </DialogContent>
    </>
  );
};
