export * from "./DesktopChannelList";
export * from "./MobileChannelList";

export const ChatColorSet = {
  '--sendbird-light-primary-900': '#262626',
  '--sendbird-light-primary-800': '#474747',
  '--sendbird-light-primary-700': '#666666',
  '--sendbird-light-primary-600': '#7b7b7b',
  '--sendbird-light-primary-500': '#a4a4a4',
  '--sendbird-light-primary-400': '#c3c3c3',
  '--sendbird-light-primary-300': '#000',
  '--sendbird-light-primary-200': '#f1f1f1',
  '--sendbird-light-primary-100': '#f6f6f6',
};

