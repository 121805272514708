import React, {
  useEffect,
  useState,
} from 'react';

import { StaticMap } from 'components/Map';
import copy from 'copy-to-clipboard';
import get from 'lodash/get';
import { useQuery } from 'react-query';
import {
  useLocation,
  useNavigate,
} from 'react-router-dom';
import { getHolderSuggestions } from 'service';

import { IHolder } from '@interfaces';
import {
  Box,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  Select,
  TextField,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import { makeStyles } from '@mui/styles';

import { daysOfWeek } from '../../utils';
import {
  StyledButton2,
  StyledTypography,
  StyledTypography2,
  StyledTypography3,
  StyledTypography4,
  StyledTypography5,
  StyledTypography6,
} from './style.js';
import OversizedPackageAccepted from './OversizedPackageAccepted';
import LongTermHolde from './LongTermHolde';

const useStyles = makeStyles((theme) => ({
  sendPackage: {
    "& .send": {
      fontFamily: "Rubik, sans-serif",
      fontWeight: 450,
      fontSize: "1.9rem",
      color: "#ff741e",
      "@media (max-width: 768px)": {
        fontSize: "1.5rem",
      },
      "@media (max-width: 480px)": {
        fontSize: "1.2rem",
      },
    },

    "& .package": {
      fontFamily: "Rubik, sans-serif",
      fontWeight: 450,
      fontSize: "1.9rem",
      color: "primary",
      "@media (max-width: 768px)": {
        fontSize: "1.5rem",
      },
      "@media (max-width: 480px)": {
        fontSize: "1.2rem",
      },
    },
  },
  label: {
    fontSize: "17px",
    color: "primary",
    "@media (max-width: 768px)": {
      fontSize: "1rem",
    },
    "@media (max-width: 480px)": {
      fontSize: "0.8rem",
    },
  },
  checkbox: {
    fontSize: "16px",
    color: "primary",
    "@media (max-width: 768px)": {
      fontSize: "1rem",
    },
    "@media (max-width: 480px)": {
      fontSize: "0.8rem",
    },
  },
}));

const getUserLocation = async () => {
  try {
    const position = await new Promise<GeolocationPosition>(
      (resolve, reject) => {
        navigator.geolocation.getCurrentPosition(
          (position) => resolve(position),
          (error) => reject(error)
        );
      }
    );
    console.log("User location obtained:", position.coords);

    return position.coords;
  } catch (error) {
    console.error("Error getting user location:", error);
    throw error;
  }
};

const OrderCreationStepOne = () => {
  const theme = useTheme();
  const classes = useStyles();
  const navigate = useNavigate();
  //const { latitude, longitude } = useParams();

  const handleNextStep = () => {
    navigate(`/OrderCreationStepTwo/${addressId}`);
  };

  //const [quantityError, setQuantityError] = useState("");
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const isMediumScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [holderSuggestions, setHolderSuggestions] = React.useState({
    loading: true,
    data: [],
  });
  const [addressId, setAddressId] = useState<string | null>(null);
  const [userLocation, setUserLocation] = React.useState({ lat: 0, lon: 0 });
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const latitude = queryParams.get("latitude");
  const longitude = queryParams.get("longitude");

  const roundedInputStyle = {
    borderRadius: 10,
    border: "0.1px solid black",
    backgroundColor: "#f8f8f8",
    fontSize: "16.2px",
    color: "primary",
  };

  if (window.innerWidth <= 768) {
    roundedInputStyle.fontSize = "0.9rem";
  }
  if (window.innerWidth <= 414) {
    roundedInputStyle.fontSize = "0.76rem";
  }

  //const zoom = 13;

  //const userAddressId = getCurrentUser()?.address?.id;

  /*const [userCoordinates, setUserCoordinates] = useState(null);

  useEffect(() => {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        const { latitude, longitude } = position.coords;
        setUserCoordinates({ latitude, longitude });
      },
      (error) => {
        console.error("Error getting user coordinates:", error);
      }
    );
  }, []); */

  React.useEffect(() => {
    async function fetchData() {
      try {
        let location;
        if (latitude && longitude) {
          location = {
            latitude: parseFloat(latitude),
            longitude: parseFloat(longitude),
          };
        } else {
          location = await getUserLocation();
        }
        setUserLocation({ lat: location.latitude, lon: location.longitude });

        const results = await getHolderSuggestions(
          location.latitude,
          location.longitude
        );
        if (results && results.addresses.length) {
          setHolderSuggestions({ loading: false, data: results.addresses });
        } else {
          setHolderSuggestions({ loading: false, data: [] });
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        setHolderSuggestions({ loading: false, data: [] });
      }
    }

    fetchData();
  }, [latitude, longitude]);

  const {
    data: holderAddressSuggestionsResults = { results: [] },
    isLoading,
    isFetching,
    error,
  } = useQuery(
    [{ lat: userLocation.lat, lon: userLocation.lon }],
    () => getHolderSuggestions(userLocation.lat, userLocation.lon),
    {
      enabled: !!userLocation.lat && !!userLocation.lon,
    }
  );

  React.useEffect(() => {
    if (
      !isLoading &&
      !isFetching &&
      !error &&
      holderAddressSuggestionsResults &&
      holderAddressSuggestionsResults.results &&
      holderAddressSuggestionsResults.results.length &&
      holderSuggestions.length === 0
    ) {
      setHolderSuggestions(holderAddressSuggestionsResults.results);
    }
  }, [
    holderAddressSuggestionsResults,
    isLoading,
    isFetching,
    error,
    holderSuggestions.length,
  ]);

  if (error) {
    console.error("Error fetching holder suggestions:", error);
  }

  const [selectedHolder, setSelectedHolder] = React.useState<IHolder | null>(
    null
  );

  console.log("selectedHolder:", selectedHolder);

  useEffect(() => {
    if (selectedHolder && selectedHolder._id) {
      setAddressId(selectedHolder._id);
    } else {
      setAddressId(null);
    }
  }, [selectedHolder]);

  const copyToClipboard = (value: string) => {
    copy(value);
    alert(`You have copied "${value}"`);
  };
  const handleFieldClick = (field: string) => {
    if (selectedHolder) {
      const valueToCopy = get(selectedHolder, field) || "N/A";
      copyToClipboard(valueToCopy);
    }
  };

  const haversineDistance = (coords1, coords2) => {
    const toRad = (value) => (value * Math.PI) / 180;
    const R = 6371;

    const dLat = toRad(coords2.latitude - coords1.latitude);
    const dLon = toRad(coords2.longitude - coords1.longitude);

    const lat1 = toRad(coords1.latitude);
    const lat2 = toRad(coords2.latitude);

    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.sin(dLon / 2) * Math.sin(dLon / 2) * Math.cos(lat1) * Math.cos(lat2);

    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    const distance = R * c;

    return distance.toFixed(2);
  };

  return (
    <>
      <DialogTitle
        className={classes.sendPackage}
        align="center"
        style={{ marginTop: "20px" }}
      >
        <span className="send">Send </span>
        <span className="package">Package</span>
      </DialogTitle>

      <StyledTypography
        align="center"
        sx={{
          paddingTop: isSmallScreen ? 1 : isMediumScreen ? 1 : 1.5,
          paddingLeft: isSmallScreen ? 5 : isMediumScreen ? 10 : 10,
          paddingRight: isSmallScreen ? 5 : isMediumScreen ? 10 : 10,
        }}
      >
        Step 1 of 2
      </StyledTypography>
      <DialogContent
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <div style={{ padding: "20px" }}>
          <StyledTypography2>Select a nearby Holder</StyledTypography2>
          <StyledTypography3>
            (you can add or change your current location in Account settings)
          </StyledTypography3>

          <Select
            labelId="select-holder-label"
            id="select-holder-id"
            label="Select holder"
            value={selectedHolder ? selectedHolder._id : ""}
            onChange={(event) => {
              const selectedHolder = holderSuggestions.data.find(
                (item) => item._id === event.target.value
              );
              setSelectedHolder(selectedHolder || null);
            }}
            sx={{
              marginTop: "10px",
              "@media (max-width: 768px)": {
                fontSize: "0.85rem",
              },
              "@media (max-width: 480px)": {
                fontSize: "0.75rem",
              },
            }}
          >
            {holderSuggestions.loading ? (
              <MenuItem value="" disabled>
                Loading data...
              </MenuItem>
            ) : (
              holderSuggestions.data.map((item) => {
                const distance = haversineDistance(
                  { latitude: userLocation.lat, longitude: userLocation.lon },
                  {
                    latitude: item.location.coordinates[1],
                    longitude: item.location.coordinates[0],
                  }
                );
                return (
                  <MenuItem key={item._id} value={item._id}>
                    {item.name} - {distance} km away
                  </MenuItem>
                );
              })
            )}
          </Select>
        </div>
      </DialogContent>

      {selectedHolder && (
        <>
          <Grid container justifyContent="center" style={{ height: "100vh" }}>
            <Grid item xs={12} sm={8} md={6} lg={4} xl={4}>
              <DialogContent
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: isSmallScreen ? "column" : "row",
                    alignItems: "center",
                    justifyContent: "center",
                    marginBottom: "20px",
                  }}
                >
                  <div style={{ textAlign: "left", marginRight: "10px" }}>
                    <StaticMap
                      size={"285x285"}
                      latitude={selectedHolder.location.coordinates[1]}
                      longitude={selectedHolder.location.coordinates[0]}
                    />
                  </div>
                  <div>
                    <StyledTypography4 variant="h6">
                      <span style={{ color: "#ff741e" }}>Holder </span>
                      <span style={{ color: "primary" }}>Hours </span>
                    </StyledTypography4>
                    {daysOfWeek.map((day, index) => {
                      const capitalizedDay =
                        day.charAt(0).toUpperCase() + day.slice(1);
                      const dayInfo = selectedHolder.operationHours.find(
                        (item) => item.day.toLowerCase() === day.toLowerCase()
                      );
                      const formatTime = (time) =>
                        new Date(0, 0, 0, time, 0).toLocaleTimeString("en-US", {
                          hour: "2-digit",
                          minute: "2-digit",
                          hour12: false,
                        });
                      return (
                        <Grid item xs={12} key={index}>
                          <StyledTypography5>
                            {capitalizedDay}:{" "}
                            {dayInfo && dayInfo.from !== -1 && dayInfo.to !== -1
                              ? `${formatTime(dayInfo.from)} - ${formatTime(
                                  dayInfo.to
                                )}`
                              : "Not Working"}
                          </StyledTypography5>
                        </Grid>
                      );
                    })}
                    {/* Long Term holder */}
                    {(selectedHolder?.maxHoldingDays > 3 && (
                      <div style={{ marginLeft: "30px", marginTop: "10px" }}>
                        <LongTermHolde />
                      </div>
                      )
                    )}
                    {/* Oversized package accepted */}
                    {(selectedHolder?.maxWeight >= 20 || selectedHolder?.maxTotalLength >= 115) && (
                      <div style={{ marginLeft: "30px", marginTop: "10px" }}>
                        <OversizedPackageAccepted />
                      </div>
                    )}
                  </div>
                </div>

                <StyledTypography6>
                  <span style={{ color: "#ff741e" }}>Copy and paste </span>
                  <span style={{ color: "primary" }}>
                    the above Holder address into the 'Shipping Address' of your
                    order, then complete your purchase. When you've completed
                    your order, return here and click 'Next Step'.{" "}
                  </span>
                </StyledTypography6>

                <Grid
                  container
                  justifyContent="center"
                  alignItems="center"
                  spacing={2}
                  sx={{
                    padding: 5,
                    "@media (max-width: 768px)": {
                      padding: 0,
                    },
                    "@media (max-width: 480px)": {
                      padding: 0,
                    },
                  }}
                >
                  <Grid item xs={12}>
                    <FormControl fullWidth>
                      <Box display="flex" alignItems="center">
                        <div style={{ width: "35%" }}>
                          <label
                            htmlFor="street-address"
                            className={classes.label}
                          >
                            Address:
                          </label>
                        </div>
                        <div style={{ width: "65%" }}>
                          <TextField
                            id="street-address"
                            value={
                              (selectedHolder
                                ? get(selectedHolder, "formattedAddress") ||
                                  "N/A"
                                : "N/A") as string
                            }
                            fullWidth
                            multiline
                            size="medium"
                            helperText="click to copy address"
                            color="secondary"
                            InputProps={{
                              style: roundedInputStyle,
                              readOnly: true,
                              onClick: () =>
                                handleFieldClick("formattedAddress"),
                            }}
                          />
                        </div>
                      </Box>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} style={{ marginTop: 20 }}>
                    <FormControl fullWidth>
                      <Box display="flex" alignItems="center">
                        <div style={{ width: "35%" }}>
                          <label htmlFor="address-2" className={classes.label}>
                            Street & Unit Number:
                          </label>
                        </div>
                        <div style={{ width: "65%" }}>
                          <TextField
                            id="address-2"
                            value={
                              (selectedHolder
                                ? get(selectedHolder, "street") || "N/A"
                                : "N/A") as string
                            }
                            fullWidth
                            multiline
                            size="medium"
                            helperText="click to copy address"
                            color="secondary"
                            InputProps={{
                              style: roundedInputStyle,
                              readOnly: true,
                              onClick: () => handleFieldClick("street"),
                            }}
                          />
                        </div>
                      </Box>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} style={{ marginTop: 20 }}>
                    <FormControl fullWidth>
                      <Box display="flex" alignItems="center">
                        <div style={{ width: "35%" }}>
                          <label htmlFor="zip-code" className={classes.label}>
                            Postal Code:
                          </label>
                        </div>
                        <div style={{ width: "65%" }}>
                          <TextField
                            id="zip-code"
                            value={
                              (selectedHolder
                                ? get(selectedHolder, "postal") || "N/A"
                                : "N/A") as string
                            }
                            fullWidth
                            multiline
                            size="medium"
                            helperText="click to copy address"
                            color="secondary"
                            InputProps={{
                              style: roundedInputStyle,
                              readOnly: true,
                              onClick: () => handleFieldClick("postal"),
                            }}
                          />
                        </div>
                      </Box>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} style={{ marginTop: 20 }}>
                    <FormControl fullWidth>
                      <Box display="flex" alignItems="center">
                        <div style={{ width: "35%" }}>
                          <label htmlFor="city" className={classes.label}>
                            City / Town:
                          </label>
                        </div>
                        <div style={{ width: "65%" }}>
                          <TextField
                            id="city"
                            value={
                              (selectedHolder
                                ? get(selectedHolder, "city") || "N/A"
                                : "N/A") as string
                            }
                            fullWidth
                            multiline
                            size="medium"
                            helperText="click to copy address"
                            color="secondary"
                            InputProps={{
                              style: roundedInputStyle,
                              readOnly: true,
                              onClick: () => handleFieldClick("city"),
                            }}
                          />
                        </div>
                      </Box>
                    </FormControl>
                  </Grid>
                </Grid>
              </DialogContent>
              <Box display="flex" justifyContent="center">
                <StyledButton2
                  variant="contained"
                  onClick={handleNextStep}
                  disabled={!selectedHolder}
                  sx={{ marginBottom: "5%" }}
                >
                  Next Step
                </StyledButton2>
              </Box>
            </Grid>
          </Grid>
        </>
      )}
    </>
  );
};

export { OrderCreationStepOne, useStyles };
