import * as React from 'react';
import { useState } from 'react';

import CssBaseline from '@mui/material/CssBaseline';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import { ThemeProvider } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

import SignInFormContent from '../components/Auth/SigninFormContent';
import SignupFormContent from '../components/Auth/SignupFormContent';
import theme from '../theme';

function Copyright(props: any) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      style={{
        fontFamily: "Rubik, sans-serif",
        marginBottom: "16px",
      }}
      {...props}
    >
      {"Copyright © "}
      <Link color="inherit" href="https://holdthisplease.com/">
        Hold This
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

export default function SignInSignUp() {
  const [isSignIn, setIsSignIn] = useState(true);

  return (
    <React.Fragment>
      <ThemeProvider theme={theme}>
        <div
          style={{ display: "flex", flexDirection: "column", height: "100vh" }}
        >
          <Grid container component="main" sx={{ height: "100vh" }}>
            <CssBaseline />
            <Grid
              item
              xs={12}
              md={6}
              component={Paper}
              elevation={6}
              square
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-around",
                alignItems: "center",
              }}
            >
              <Grid
                container
                sx={{
                  maxWidth: "420px",
                  "@media (max-width: 450px)": {
                    maxWidth: "280px",
                  },
                }}
              >
                <Grid item xs={12}>
                  {isSignIn ? (
                    <SignInFormContent
                      setIsSignin={setIsSignIn}
                    ></SignInFormContent>
                  ) : (
                    <SignupFormContent></SignupFormContent>
                  )}
                  <Copyright sx={{ mt: 3 }} />
                </Grid>
              </Grid>
            </Grid>
            <Grid
              item
              xs={12}
              md={6}
              sx={{
                background: "linear-gradient(to bottom left, #ffecbb, #ffffff)",
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
                backgroundPosition: "center",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-around",
              }}
            >
              <Grid
                sx={{
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "column",
                  marginTop: "-90px",
                  "@media (max-width: 900px)": {
                    marginTop: "-20px",
                  },
                }}
              >
                <img
                  className="LogInPage"
                  alt="Log In Page"
                  src={`${process.env.PUBLIC_URL}/LogInPage.png`}
                  style={{
                    height: "auto",
                    width: "53%",
                  }}
                />
              </Grid>
              <div>
                <Typography
                  variant="h5"
                  align="center"
                  sx={{
                    marginTop: "-100px",
                    "@media (max-width: 900px)": {
                      marginTop: "40px",
                    },
                    color: "#474747",
                    fontFamily: "Questrial, sans-serif",
                  }}
                >
                  Secure Your Packages
                </Typography>
                <Typography
                  variant="h5"
                  align="center"
                  sx={{
                    "@media (max-width: 900px)": {
                      marginBottom: "100px",
                    },
                    color: "#474747",
                    fontFamily: "Questrial, sans-serif",
                  }}
                >
                  on your schedule.
                </Typography>
              </div>
            </Grid>

            <img
              id="site-logo"
              src={`${process.env.PUBLIC_URL}/logowithname.png`}
              alt="Site Logo"
              style={{
                position: "absolute",
                top: "16px",
                left: "10px",
                width: "160px",
                height: "auto",
              }}
            />
          </Grid>
        </div>
      </ThemeProvider>
    </React.Fragment>
  );
}
